import styled, { css } from "styled-components";
import { respondTo } from "../../../styles/respondTo";
import { defaultTheme } from "../../../styles/theme";
import { Section } from "../../UI";
import { breakpoints } from "../../../styles/breakpoints";
import { BasicHeader } from "../../headers/basic/basic-header.styled";
const gridFooter = require("../../../assets/images/footer/orbits.svg");

export const ContactUsContainer = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  position: relative;
  color: #fff;
`;
export const LeftAndRightContainer = styled.div`
  display: flex;
  flex-direction: row;
  ${respondTo.tabletL`
   flex-direction: column;
   align-items:center;
  `};
`;

export const ContactUsHeader = styled(BasicHeader)`
  z-index: 2;
  position: absolute;
  top: 60px;
  width: 100%;
  left: 0;
  right: 0;
  margin: 0;
  padding: 0;
`;

export const ContactUsTitle = styled.div`
  font-family: "Raleway";
  position: absolute;
  display: flex;
  font-weight: 300;
  font-size: 3.8rem;
  text-transform: uppercase;
  color: ${defaultTheme.colors.white};
  mix-blend-mode: difference;
  align-items: center;
  margin: 0 auto;
  justify-content: center;
  top: 40px;
  letter-spacing: 0.2em;
  z-index: 2;
  width: 100%;
  text-align: center;
  padding: 24px;

  ${respondTo.desktopL`
    font-size: 2.8rem; `};

  ${respondTo.tabletL`
  align-items: flex-start;
  position: static;
  text-align: left;
  font-size: 2rem;

  `};
`;

export const LeftFooterContainer = styled.div`
  background-color: ${defaultTheme.colors.brandBlack};
  width: 52.5vw;
  display: flex;
  align-items: center;
  justify-items: center;
  flex-direction: column;
  margin-top: 50px;
  margin-left: 20px;
  margin-right: 20px;

  ${respondTo.tabletL`
   width: 100vw;
  `};
`;

export const RightFooterContainer = styled.div`
  display: flex;
  gap: 100px;
  background-color: ${defaultTheme.colors.lightWhite};
  width: 47.5vw;
  display: flex;
  align-items: flex-end;
  background-image: url(${gridFooter});
  background-position-y: -400px;
  background-position-x: 200px;
  background-size: contain;
  background-repeat: no-repeat;
  position: relative;
  z-index: 1;
  padding: 90px;

  ${respondTo.desktopL`
  flex-direction: column;
  align-items: flex-start;
  gap:0;
  `};

  ${respondTo.tabletL`
   width: 100vw;
   align-items: center;
   padding-top:0;

  `};
  ${respondTo.mobile`
  background-position-y: -250px;
  background-position-x: 100px;
  `}
`;
export const SmallFormContainer = styled.div`
  max-width: 700px;
  margin-top: 250px;
  margin-left: ${defaultTheme.spacing.s};
  margin-right: ${defaultTheme.spacing.s};
  ${respondTo.tabletL`
     margin-top: 50px;
  `};
  ${respondTo.tabletS`
  max-width: 565px;
  `}
  ${respondTo.mobile`
  max-width: 400px;
  margin-top: 0px;
  `}
  ${respondTo.mobileM`
  max-width: 340px;
  `}
  ${respondTo.mobile360`
  max-width: 300px;
  `}
  ${respondTo.mobile326`
  max-width: 245px;
  `}
`;
export const SmallForm = styled.form`
  margin-top: ${defaultTheme.spacing.m};
`;

export const SmallFormErrorMessage = styled.p`
  color: ${defaultTheme.colors.red};
  margin-bottom: 2vw;
`;
export const ContactButtonContainer = styled.div`
  margin: ${defaultTheme.spacing.m} 0;
  display: flex;
  width: 100%;
  justify-content: center;
  position: relative;
  top: 40px;
`;

export const SubmitButtonContainer = styled.div`
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  ${respondTo.tabletL`
     margin-top: 10px;
     margin-bottom: 10px;
  `};
`;

export const ButtonWrapper = styled.button`
  display: flex;
  align-items: center;
  background: transparent;
  cursor: pointer;
  height: 30px;
  border: 0px;

  @media (min-width: 1200px) {
    margin-top: 2rem;
  }
`;

export const Plus = styled.div`
  color: ${defaultTheme.colors.geeksGreen};
  font-size: 2rem;
`;

export const ArrowMobileFooter = styled.div`
  display: none;
  ${respondTo.tabletL`
display: inline;
color: ${defaultTheme.colors.lightGrey};
margin-left: ${defaultTheme.spacing.base};
`}
`;

export const ArrowRightFooter = styled.div`
  color: ${defaultTheme.colors.lightGrey};
  font-size: ${defaultTheme.fontSize.l};
  margin-left: ${defaultTheme.spacing.base};
  ${respondTo.tabletL`
  display: none;
  `}
`;

export const TextContainer = styled.div``;

export const CompanyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 50px;
`;

export const IconContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin: 24px auto 64px;
`;
export const IconImg = styled.img`
  display: inline-block;
  height: 40px;
  width: 40px;
  padding: 18px;
  background: white;
  border-radius: 8px;
  ${respondTo.tabletL`
     height: 40px;
     width: 40px;
  `};
`;

export const IconLink = styled.a`
  width: 10px;
`;

export const LogoImg = styled.img`
  width: 300px;
  height: 120px;
`;

export const FollowUsTextContainer = styled.span`
  color: ${defaultTheme.colors.brandBlack};
  font-size: ${defaultTheme.fontSize.xl};
  text-transform: uppercase;
  margin-top: 2vw;
  letter-spacing: 0.3vw;
  font-weight: light;
`;
