import * as React from "react";

const scrollToElement = require("scroll-to-element");

import { FaBars, FaTimes } from "react-icons/fa";

import { NavContainer, NavIcon, MobileIcon, NavMenu, NavItem, MobileSwitch, DesktopSwitch } from "./Navigation.styled";
import { Align, Switch } from "../UI";

import { useViewport } from "../../common/hooks";
import { ActiveSectionContext } from "../../common/context/active-context";
import { navigate } from "gatsby";

const logo = require("../../assets/images/logo.svg");
interface NavigationProps {
  navOptions: {
    id: string;
    name: string;
  }[];
}

export const Navigation = ({ navOptions }: NavigationProps) => {
  const [clicked, setClicked] = React.useState(false);

  const { activeSectionId } = React.useContext(ActiveSectionContext);

  return (
    <NavContainer>
      <Align direction="row" align="center" justify="space-between">
        <NavIcon
          src={logo}
          alt="logo"
          onClick={() => {
            navigate(`#`);
          }}
        />
        <Align direction="row" align="center" justify="flex-end">
          <MobileSwitch>
            <Switch />
          </MobileSwitch>
          <MobileIcon onClick={() => setClicked(!clicked)} clicked={clicked}>
            {clicked ? <FaTimes /> : <FaBars />}
          </MobileIcon>
          <NavMenu onClick={() => setClicked(!clicked)} clicked={clicked}>
            {navOptions.map(option => (
              <NavItem
                key={option.id}
                onClick={() =>
                  scrollToElement(`#${option.id}`, {
                    offset: -80,
                    ease: "out-circ",
                    duration: 1000
                  })
                }
                active={activeSectionId === option.id}
                name={option.id}
              >
                {option.name.toUpperCase()}
              </NavItem>
            ))}
            <DesktopSwitch>
              <Switch />
            </DesktopSwitch>
          </NavMenu>
        </Align>
      </Align>
    </NavContainer>
  );
};
