import React from "react";
import { TechIconContainer, TechTitle } from "./TechnologyType.styled";

export const TechIcon = ({ tech, technologyName, zIndex }) => {
  const [icon, setIcon] = React.useState(tech.icon);
  return (
    <TechIconContainer zIndex={zIndex}>
      <img
        src={icon}
        onMouseEnter={() => setIcon(tech.hoverIcon)}
        onMouseOut={() => setIcon(tech.icon)}
        alt={technologyName}
        height="30"
      />
      <TechTitle wide={tech.title === "React Native"}>{tech.title}</TechTitle>
    </TechIconContainer>
  );
};
