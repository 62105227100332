import styled from "styled-components";
import { respondTo } from "../../../styles/respondTo";
import { defaultTheme } from "../../../styles/theme";

export const OurTeamSectionDivider = styled.div`
  height: 40px;
  width: 100%;
`;

export const TeamMembersContainer = styled.div`
  position: relative;
  width: 70%;
  margin: ${defaultTheme.spacing.xl} auto;
`;

export const CounterSegmentContainer = styled.div`
  background: ${defaultTheme.colors.brandBlack};
  width: 60%;
  ${respondTo.desktop`
   width: 50%;
  `}
  ${respondTo.tablet`
   width: 40%;
  `}
    ${respondTo.tabletS`
   width: 30%;
  `}
  ${respondTo.mobile`
  display: none;
 `}
`;

export const StatisticsContainer = styled.div`
  display: flex;
  justify-content: center;
  ${defaultTheme.colors.white};
`;

export const Counter = styled.div`
  color: ${defaultTheme.colors.white};
  font-family: "Raleway";
  font-feature-settings: "lnum" 1;
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  border-right: solid rgba(130, 183, 60, 0.5);
  border-right-width: ${({ borderWidth }: { borderWidth: boolean }) => (borderWidth ? "1px" : "0")};
  font-size: ${defaultTheme.fontSize.xxxxl};
  width: 200px;
  padding: ${defaultTheme.spacing.xs} 10px ${defaultTheme.spacing.xs};
  ${respondTo.tablet`
  font-size: ${defaultTheme.fontSize.xxxxl};
  `}
  ${respondTo.tabletS`
   width: 80%;
   font-size: ${defaultTheme.fontSize.xl};
  `}
`;

export const DescriptionSpan = styled.span`
  font-family: "Raleway";
  color: ${defaultTheme.colors.geeksGreen};
  padding-top: ${defaultTheme.spacing.xs};
  font-size: ${defaultTheme.fontSize.font22};

  ${respondTo.tablet`
  font-size: ${defaultTheme.fontSize.l};
  `}
  ${respondTo.tabletS`
   width: 80%;
   font-size: 18px;
  `}
`;

export const CounterSegmentContainerMobile = styled.div`
  display: none;
  ${respondTo.mobile`
  display: flex;
  background: ${defaultTheme.colors.brandBlack};
  width: 100vw;
  justify-content: center;
`}
`;

export const StatisticsContainerMobile = styled.div`
  display: flex;
  justify-content: space-between;
  color: white;

  ${respondTo.mobile`
    flex-wrap: wrap;
    row-gap: 20px;
  `}
`;

export const CounterMobile = styled.div`
  font-family: "Segoe UI";
  color: ${defaultTheme.colors.geeksGreen};
  display: flex;
  flex-direction: column;
  text-align: center;
  font-size: ${defaultTheme.fontSize.xl};
  width: 80px;
  padding: 0 10px;
  border-right: solid ${defaultTheme.colors.geeksGreen};
  border-right-width: ${({ borderWidth }: { borderWidth: boolean }) => (borderWidth ? "1px" : "0")};
  cursor: pointer;

  ${respondTo.mobile`
    width: 50%;
    box-sizing: border-box;

    &:nth-child(2) {
      border-right: none;
    }
  `}
`;

export const DescriptionSpanMobile = styled.span`
  font-family: "Raleway";
  color: ${defaultTheme.colors.white};
  font-size: 10px;
  padding-top: ${defaultTheme.spacing.xxs};
`;

export const LeftLine = styled.div`
  position: relative;
  margin-top: ${defaultTheme.spacing.xs};
  height: 20px;
  border-left: 1px solid white;
`;

export const OurTeamTitle = styled.div`
  display: flex;
  font-family: "Raleway";
  font-weight: 300;
  justify-content: center;
  padding-top: ${defaultTheme.spacing.xxxl};
  white-space: nowrap;
  letter-spacing: ${defaultTheme.spacing.s};
  text-transform: uppercase;
  font-size: ${defaultTheme.fontSize.xxxxxxl};

  ${respondTo.tabletS`
    margin: 0 ${defaultTheme.spacing.s}
  `}
    ${respondTo.mobile`
   margin: 0 ${defaultTheme.spacing.xs};
  `}
  }
  ${respondTo.desktop`
  font-size: ${defaultTheme.fontSize.xxxxl};
  `};
  ${respondTo.tablet`
   font-size: ${defaultTheme.fontSize.xxxl};
   letter-spacing: ${defaultTheme.spacing.xxs};
  `}
  ${respondTo.mobile`
   letter-spacing: 4px;
  `}
   ${respondTo.mobileM`
   font-size: ${defaultTheme.fontSize.xxl};
  `}
    ${respondTo.mobileS`
  font-size: ${defaultTheme.fontSize.xl}
  `};
  ${respondTo.mobileXS`
   font-size: ${defaultTheme.fontSize.l};
  `}
`;

export const QuoteContainer = styled.div`
  font-family: "Raleway";
  font-weight: 300;
  font-size: ${defaultTheme.fontSize.xl};
  padding-top: ${defaultTheme.spacing.s};
  color: ${defaultTheme.colors.white};
  display: flex;
  flex-direction: column;
  justify-content: center;

  ${respondTo.desktop`
  font-size: ${defaultTheme.fontSize.l};
  `};
  ${respondTo.tablet`
  font-size: ${defaultTheme.fontSize.m};
  `};
  ${respondTo.mobile`
  font-size: ${defaultTheme.fontSize.s};
  `};
  ${respondTo.mobileM`
  font-size: ${defaultTheme.fontSize.xs};
  `};
  ${respondTo.mobileS`
  font-size: 10px;
  `};
  span:last-child {
    color: ${defaultTheme.colors.geeksGreen};
  }
`;
