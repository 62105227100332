import styled from "styled-components";

import { respondTo } from "../../../styles/respondTo";
import { colors } from "../../../styles/newColors";
import { defaultTheme } from "../../../styles/theme";

export const HomeWrapper = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: space-between;
  width: 100vw;
  background: ${colors.darkGrey};
  min-height: 100vh;
  box-sizing: border-box;
  padding-top: 0;

  ${respondTo.desktop4k` 
    padding-top: 80px;
  `};

  ${respondTo.desktopL` 
    justify-content: space-between;
  `};

  ${respondTo.tabletL` 
   padding-top: 80px;
    height: initial;
  `};
`;

export const BannerWraper = styled.div`
  display: flex;
  flex-direction: row;
  min-height: 650px;
  justify-content: space-between;
  align-items: center;
  padding-right: 140px;
  height: calc(100vh - 220px);

  ${respondTo.desktopL` 
    padding-right: 80px;
  `};
  ${respondTo.tabletL` 
    display: flex;
    flex-direction: column;
    padding-right: 0;
    justify-content: flex-start;
    align-items: center;
    width: 100wv;
    height: initial;
    margin-top: -20px;
    padding-bottom: 90px;
    min-height: initial;
  `};
`;

export const HomeImgContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  width: 38%;
  padding-top: 60px;

  ${respondTo.desktop4k` 
    width: 30%;
    `};
  ${respondTo.desktopL` 
    margin-top: 0px;
  `};
  ${respondTo.desktopS` 
    margin-top: -40px;
  `};
  ${respondTo.tabletL` 
    transform: rotate(90deg);
    width: 60%;
    top: 0%;
    justify-content: center;
    padding: 30px 0px;
    max-height: 300px;
  `};
`;

export const SkyImg = styled.img`
  object-fit: contain;
  overflow: hidden;
`;

export const HomeTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  z-index: 2;
  text-transform: lowercase;
  margin-right: ${defaultTheme.spacing.m};
  width: 45%;

  ${respondTo.desktop` 
    width: 40%;
  `};
  ${respondTo.tabletL` 
    margin-left: 15%;
    width: 65%;
  `};
  ${respondTo.mobileM` 
    margin-left: 0;
  `};
`;

export const FirstTextContainer = styled.div`
  display: flex;
  width: 100%;
  ${respondTo.mobile570` 
    display: none;
  `};
`;
export const TextContainer = styled.div`
  padding-right: 36px;
`;

export const LightContainer = styled.div`
  z-index: 1;
  width: 100%;
  height: 100%;
  position: absolute;
  display: flex;
`;

export const LightImg = styled.img`
  position: relative;
  width: 100%;
  height: 100%;
`;

export const ButtonContainer = styled.div`
  z-index: 2;
  display: flex;
  flex-direction: row;
  margin-left: 0px;
  margin-top: 60px;
  margin-bottom: 40px;
  justify-content: left;
  grid-column-gap: 20px;
  width: 100%;

  ${respondTo.desktop4k`
    width: 78%;
  `};
  ${respondTo.desktopMacP`
    width: 82%;
  `};
  ${respondTo.desktopMac`
    width: 87%;
    `};
  ${respondTo.desktopHD` 
    width: 96%;
  `};

  ${respondTo.desktopL` 
    margin-top: 0;
  `};
  ${respondTo.tabletL`
    width: 100%;
    justify-content: space-between;
  `};
`;

export const ContactSpan = styled.span`
  cursor: pointer;
  font-family: Raleway;
  font-weight: 100;
  font-size: 30px;
  line-height: 35px;
  width: 30%;
  text-align: center;

  color: ${colors.green};
  ${respondTo.desktop` 
    width: 30%;
    font-size: ${defaultTheme.fontSize.xl}
  `};
  ${respondTo.tabletL` 
    margin-top: 20px;
    width: 100%
  `};
  ${respondTo.mobileM` 
    width: 90%
    margin-left: 10px;
    font-size: 20px;
  `};
`;

export const Dot = styled.div`
  border-radius: 10px;
  background: ${colors.green};
  width: 10px;
  height: 10px;
  margin-top: -5px;
  margin-left: 100%;
  ${respondTo.tabletL` 
  display: none;
`};
`;

export const StartLineContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: top;
  margin-left: 0px;
  width: 40%;
  ${respondTo.tabletL` 
  display: none
`};
  ${respondTo.mobile` 
    display: none;
  `};
`;

export const EndLineContainer = styled.div`
  display: flex;
  justify-self: center;
  flex-direction: column;
  justify-content: top;
  flex-grow: 1;
  ${respondTo.tabletL` 
    display: none;
  `};
  ${respondTo.mobile` 
  display: none;
`};
`;

export const LineImg = styled.div`
  height: 40%;
  display: flex;
  justify-self: top;
  border-bottom: 1px solid ${colors.green};

  ${respondTo.tabletL` 
  height: 50%;
`};
`;

export const SocialButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  grid-column-gap: 20px;
  position: relative;
  padding-right: 60px;
  ${respondTo.desktop` 
  grid-column-gap: 10px
  `};
  ${respondTo.tabletL`
  position: absolute;
  width: 100vw;
  justify-content: center;
  margin-bottom: 50px;
  grid-column-gap: 25px
  `};
`;

export const SocialButton = styled.img`
  display: flex;
  cursor: pointer;
  width: 35px;
  height: 35px;
  ${respondTo.tabletL`
  margin-top: -40px;
  `};
`;
