import React, { useState } from "react";
import { clients } from "../../../assets/mocks/clients";
import { ActiveSectionContext } from "../../../common/context/active-context";
import { useText } from "../../../common/hooks";
import { useActiveSection } from "../../../common/hooks/useActiveSection";
import { useResize } from "../../../common/hooks/useResize";
import { defaultTheme } from "../../../styles/theme";
import { WebSectionProps } from "../../../types/types";
import { BasicHeader } from "../../headers/basic/basic-header.styled";
import { Section } from "../../UI";
import {
  ClientLogoContainer,
  ClientLogoImage,
  ClientLogoImageContainer,
  ClientsWrapper,
  ClientTitle,
  Line,
  Dot
} from "./OurClients.styles";

interface LogoProps {
  img: string;
  fontWeight?: number;
  left?: boolean;
  hoverImg: string;
  url: string;
}
export const OurClients = ({ id }: WebSectionProps) => {
  const { text } = useText();
  const sectionRef = React.useRef<HTMLDivElement>(null);
  const { setActiveSectionId } = React.useContext(ActiveSectionContext);

  const {
    elementSize: { height }
  } = useResize(sectionRef.current!);

  useActiveSection(sectionRef, setActiveSectionId, id, height);

  return (
    <Section fullWidth background={defaultTheme.colors.white} id={id}>
      <ClientsWrapper ref={sectionRef}>
        <ClientTitle>
          <Line>
            <Dot />
          </Line>
          <BasicHeader>{text("navigation.clients")}</BasicHeader>
          <Line>
            <Dot left />
          </Line>
        </ClientTitle>
        <ClientLogoContainer>
          {clients.map((client, index) => {
            return <ClientLogo key={index} img={client.img} hoverImg={client.hoverImg} url={client.url}></ClientLogo>;
          })}
        </ClientLogoContainer>
      </ClientsWrapper>
    </Section>
  );
};

const ClientLogo = ({ img, hoverImg, url }: LogoProps) => {
  const [logo, setLogo] = useState(img);
  const changeLogo = hoverImg => {
    setLogo(hoverImg);
  };
  return (
    <a href={url} target="_blank">
      <ClientLogoImageContainer>
        <ClientLogoImage
          src={logo}
          alt="logo"
          onMouseEnter={() => changeLogo(hoverImg)}
          onMouseOut={() => changeLogo(img)}
        />
      </ClientLogoImageContainer>
    </a>
  );
};
