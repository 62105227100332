const patrykImg = require("../images/team/patryk.webp");
const michalImg = require("../images/team/michał.png");
const piotrImg = require("../images/team/piotr.webp");
const hubertImg = require("../images/team/hubert.webp");
const adamImg = require("../images/team/adam.webp");
const martaImg = require("../images/team/marta.png");
const beataImg = require("../images/team/beata.webp");
const rafalImg = require("../images/team/rafal.webp");
const grzegorzImg = require("../images/team/grzesiek.webp");
const krzysiekImg = require("../images/team/krzysiek.webp");
const krzysiekkImg = require("../images/team/krzysiek_k.png");
const kasiaImg = require("../images/team/kasia.webp");
const janImg = require("../images/team/jan.webp");
const noPhoto = require("../images/team/bg_s.png");
const borysImg = require("../images/team/borys.png");
const przemekImg = require("../images/team/przemek.png");
const piotrekImg = require("../images/team/piotrek.png");
const maciekImg = require("../images/team/maciek.png");
const marcinmImg = require("../images/team/marcin_m.png");
const sebastianImg = require("../images/team/sebastian.png");
const arturImg = require("../images/team/artur.png");
const markiianImg = require("../images/team/markiian.png");
const staszekImg = require("../images/team/staszek.png");
const pawel_fImg = require("../images/team/pawel_f.png");
const korneliaImg = require("../images/team/kornelia.png");
const marek_sImg = require("../images/team/marek_s.png");
const kacperImg = require("../images/team/kacper.png");
const kamilImg = require("../images/team/kamil.png");
const konradImg = require("../images/team/konrad.png");
const saraImg = require("../images/team/sara.png");
const wojtekImg = require("../images/team/wojtek.png");


export const team = [
  {
    img: michalImg,
    name: "Michał",
    role: "CTO"
  },
  {
    img: piotrImg,
    name: "Piotr",
    role: "CEO"
  },
  {
    img: beataImg,
    name: "Beata",
    role: "HR & Finance Manager"
  },
  {
    img: rafalImg,
    name: "Rafał",
    role: "Javascript Developer"
  },
  {
    img: adamImg,
    name: "Adam",
    role: "Software Developer"
  },
  {
    img: grzegorzImg,
    name: "Grzegorz",
    role: "Flutter Developer"
  },
  {
    img: patrykImg,
    name: "Patryk",
    role: "Backend Developer"
  },

  {
    img: hubertImg,
    name: "Hubert",
    role: "Javascript Developer"
  },
  {
    img: krzysiekImg,
    name: "Krzysztof",
    role: "QA Engineer"
  },
  {
    img: krzysiekkImg,
    name: "Krzysztof",
    role: "Software Developer"
  },
  {
    img: kasiaImg,
    name: "Kasia",
    role: "Frontend Developer"
  },
  {
    img: janImg,
    name: "Jan",
    role: "Javascript Developer"
  },
  {
    img: martaImg,
    name: "Marta",
    role: `Business Development Manager`
  },
  {
    img: borysImg,
    name: "Borys",
    role: `Frontend Developer`
  },
  {
    img: maciekImg,
    name: "Maciek",
    role: `Software Developer`
  },
  {
    img: marcinmImg,
    name: "Marcin",
    role: `Software Developer`
  },
  {
    img: piotrekImg,
    name: "Piotr",
    role: `Frontend Developer`
  },
  {
    img: przemekImg,
    name: "Przemysław",
    role: `DevOps`
  },
  {
    img: sebastianImg,
    name: "Sebastian",
    role: `UI/UX, Software Developer`
  },
  {
    img: noPhoto,
    name: "Zygmunt",
    role: `Electronics Engineer`
  },
  {
    img: arturImg,
    name: "Artur",
    role: `Frontend Developer`
  },
  {
    img: markiianImg,
    name: "Markiian",
    role: `Javascript Developer`
  },
  {
    img: staszekImg,
    name: "Stanisław",
    role: `Head Of Production`
  },
  {
    img: pawel_fImg,
    name: "Paweł",
    role: `Frontend Developer`
  },
  {
    img: wojtekImg,
    name: "Wojtek",
    role: `Ruby on Rails Developer`
  },
  {
    img: korneliaImg,
    name: "Kornelia",
    role: `QA Engineer`
  },
  {
    img: marek_sImg,
    name: "Marek",
    role: `Javascript Developer`
  },
  {
    img: kacperImg,
    name: "Kacper",
    role: `Project Manager`
  },
  {
    img: kamilImg,
    name: "Kamil",
    role: `Full-Stack Developer`
  },
  {
    img: konradImg,
    name: "Konrad",
    role: `Frontend Developer`
  },
    {
    img: saraImg,
    name: "Sara",
    role: `UI/UX Designer`
  }
];
