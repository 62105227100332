import * as React from "react";

import { defaultTheme } from "../../../styles/theme";

import { Align, Section, Text } from "../../UI";
import { sectionBackgrounds } from "../../../styles/section.backgrounds";
import { WebSectionProps } from "../../../types/types";
import { useViewport } from "../../../common/hooks/useViewport";
import { TechnologiesDesktopSection } from "./TechnologiesDesktop/TechnologiesDesktopSection";
import {
  MoonTitle,
  TechnologiesContainer,
  TechnologiesSubtitleContainer,
  TechnologiesTitle,
  Line,
  CirclesContainer
} from "./Technologies.styled";
import { TechnologyType } from "./TechnologyType/TechnologyType";

const postgreSQL = require("../../../assets/images/tech_icons/postgreSQL.svg");
const aws = require("../../../assets/images/tech_icons/aws.svg");
const rubyOnRails = require("../../../assets/images/tech_icons/ruby-on-rails.svg");
const strapi = require("../../../assets/images/tech_icons/strapi.svg");
const webflow = require("../../../assets/images/tech_icons/webflow.svg");
const nextJS = require("../../../assets/images/tech_icons/next.svg");
const vue = require("../../../assets/images/tech_icons/vue.svg");
const ionic = require("../../../assets/images/tech_icons/ionic.svg");
const android = require("../../../assets/images/tech_icons/android.svg");
const docker = require("../../../assets/images/tech_icons/docker.svg");
const flutter = require("../../../assets/images/tech_icons/flutter.svg");
const gatsby = require("../../../assets/images/tech_icons/gatsby.svg");
const graphql = require("../../../assets/images/tech_icons/graphql.svg");
const ios = require("../../../assets/images/tech_icons/ios.svg");
const js = require("../../../assets/images/tech_icons/javascript.svg");
const node = require("../../../assets/images/tech_icons/node.svg");
const react = require("../../../assets/images/tech_icons/react.svg");
const typescript = require("../../../assets/images/tech_icons/typescript.svg");
const webflowHover = require("../../../assets/images/tech_icons/webflow-hover.svg");
const ionicHover = require("../../../assets/images/tech_icons/ionic-hover.svg");
const nextJSHover = require("../../../assets/images/tech_icons/next-hover.svg");
const vueHover = require("../../../assets/images/tech_icons/vue-hover.svg");
const rubyOnRailsHover = require("../../../assets/images/tech_icons/ruby-on-rails-hover.svg");
const strapiHover = require("../../../assets/images/tech_icons/strapi-hover.svg");
const androidHover = require("../../../assets/images/tech_icons/android-hover.svg");
const awsHover = require("../../../assets/images/tech_icons/aws-hover.svg");
const dockerHover = require("../../../assets/images/tech_icons/docker-hover.svg");
const flutterHover = require("../../../assets/images/tech_icons/flutter-hover.svg");
const gatsbyHover = require("../../../assets/images/tech_icons/gatsby-hover.svg");
const graphqlHover = require("../../../assets/images/tech_icons/graphql-hover.svg");
const iosHover = require("../../../assets/images/tech_icons/ios-hover.svg");
const jsHover = require("../../../assets/images/tech_icons/javascript-hover.svg");
const postgreSQLHover = require("../../../assets/images/tech_icons/postgreSQL-hover.svg");
const nodeHover = require("../../../assets/images/tech_icons/node-hover.svg");
const reactHover = require("../../../assets/images/tech_icons/react-hover.svg");
const typescriptHover = require("../../../assets/images/tech_icons/typescript-hover.svg");

import { useText } from "../../../common/hooks/useText";
import { ActiveSectionContext } from "../../../common/context/active-context";
import { useActiveSection, useResize } from "../../../common/hooks";
import { useIntl } from "gatsby-plugin-react-intl";
import { BasicHeader } from "../../headers/basic/basic-header.styled";

export const Technologies = ({ id }: WebSectionProps) => {
  const sectionRef = React.useRef<HTMLDivElement>(null);
  const { setActiveSectionId } = React.useContext(ActiveSectionContext);

  const { text } = useText();

  const {
    elementSize: { height }
  } = useResize(sectionRef.current!);

  useActiveSection(sectionRef, setActiveSectionId, id, height);

  const technologies = text([
    {
      name: "Mobile",
      techs: [
        {
          icon: react,
          hoverIcon: reactHover,
          title: "React Native",
          text: "technologies.native"
        },
        {
          icon: flutter,
          hoverIcon: flutterHover,
          title: "Flutter",
          text: "technologies.flutter"
        },
        {
          icon: ionic,
          hoverIcon: ionicHover,
          title: "Ionic",
          text: "technologies.ionic"
        },

        {
          icon: android,
          hoverIcon: androidHover,
          title: "Android",
          text: "technologies.android"
        },
        {
          icon: ios,
          hoverIcon: iosHover,
          title: "IOS",
          text: "technologies.ios"
        }
      ]
    },
    {
      name: "Frontend",
      techs: [
        {
          icon: react,
          hoverIcon: reactHover,
          title: "React",
          text: "technologies.react"
        },
        {
          icon: nextJS,
          hoverIcon: nextJSHover,
          title: "Next.js",
          text: "technologies.nextJs"
        },
        {
          icon: vue,
          hoverIcon: vueHover,
          title: "Vue",
          text: "technologies.vue"
        },
        {
          icon: webflow,
          hoverIcon: webflowHover,
          title: "Webflow",
          text: "technologies.webflow"
        },
        {
          icon: js,
          hoverIcon: jsHover,
          title: "Javascript",
          text: "technologies.js"
        },
        {
          icon: typescript,
          hoverIcon: typescriptHover,
          title: "Typescript",
          text: "technologies.typescript"
        },
        {
          icon: gatsby,
          hoverIcon: gatsbyHover,
          title: "GatsbyJS",
          text: "technologies.gatsby"
        }
      ]
    },
    {
      name: "Backend",
      techs: [
        {
          icon: strapi,
          hoverIcon: strapiHover,
          title: "Strapi",
          text: "technologies.strapi"
        },
        {
          icon: node,
          hoverIcon: nodeHover,
          title: "Node",
          text: "technologies.node"
        },
        {
          icon: docker,
          hoverIcon: dockerHover,
          title: "Docker",
          text: "technologies.docker"
        },
        {
          icon: graphql,
          hoverIcon: graphqlHover,
          title: "GraphQL",
          text: "technologies.graphql"
        },
        {
          icon: rubyOnRails,
          hoverIcon: rubyOnRailsHover,
          title: "Ruby\u00A0on\u00A0rails",
          text: "technologies.rubyOnRails"
        },
        {
          icon: typescript,
          hoverIcon: typescriptHover,
          title: "Typescript",
          text: "technologies.typescript"
        },
        {
          icon: aws,
          hoverIcon: awsHover,
          title: "AWS",
          text: "technologies.aws"
        },
        {
          icon: postgreSQL,
          hoverIcon: postgreSQLHover,
          title: "PostgreSQL",
          text: "technologies.postgreSQL"
        }
      ]
    }
  ]);
  const intl = useIntl();
  return (
    <div ref={sectionRef}>
      <Section background={sectionBackgrounds.technologies} id={id}>
        <TechnologiesContainer>
          <BasicHeader>{text("navigation.technologies")}</BasicHeader>
          <TechnologiesSubtitleContainer>
            <Text size="medium" color={defaultTheme.colors.textLight}>
              {text("technologies.text")}
            </Text>
          </TechnologiesSubtitleContainer>
          <CirclesContainer>
            {technologies.map((technology, index) => (
              <TechnologyType key={index} technology={technology} />
            ))}
          </CirclesContainer>
        </TechnologiesContainer>
      </Section>
    </div>
  );
};
