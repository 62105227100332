import styled from "styled-components";
import { defaultTheme } from "../../../styles/theme";
import { respondTo } from "../../../styles/respondTo";

export const TeamMemberContainer = styled.div`
  margin: ${defaultTheme.spacing.xs} ${defaultTheme.spacing.m};
`;

export const TeamLogo = styled.div`
  position: relative;
`;

export const TeamMemberImageContainer = styled.div`
  display: flex;
  position: absolute;
  z-index: -1;
  top: 50%;
  left: 50%;
  transform: translate(-47.5%, -50%);
  width: 85%;
  height: 85%;
  border-radius: 50%;
  overflow: hidden;
`;

export const TeamMemberName = styled.span`
  font-family: "Raleway";
  font-size: ${defaultTheme.fontSize.xxl};
  color: ${defaultTheme.colors.white};
  padding: 4px 0;
  text-transform: uppercase;
  ${respondTo.tabletL`
  font-size: ${defaultTheme.fontSize.l};
  `};
  ${respondTo.tabletS`
  font-size: ${defaultTheme.fontSize.xl};
  `};
  ${respondTo.mobile`
   font-size: ${defaultTheme.fontSize.l};
  `};
`;

export const TeamMemberRole = styled.span`
  font-family: "Raleway";
  color: ${({ color }: { color: string }) => color};
  font-size: ${defaultTheme.fontSize.xl};
  text-align: center;
  height: 85px;
  ${respondTo.tabletL`
  font-size: ${defaultTheme.fontSize.m};
  `};
  ${respondTo.tabletS`
  font-size: ${defaultTheme.fontSize.m};
  `};
  ${respondTo.mobile`
   font-size: ${defaultTheme.fontSize.xs};
   height: 35px;
  `};
`;

export const TeamMemberBorder = styled.img`
  width: 180px;
  height: 180px;
  ${respondTo.desktopL`
  width: 150px;
  height: 150px;
  `};
  ${respondTo.tabletS`
  width: 130px;
  height: 130px;
  `};
  ${respondTo.mobile`
  width: 100px;
  height: 100px;
  `};
  ${respondTo.mobileS`
   width: 80px;
   height: 80px;
  `}
`;
