import styled from "styled-components";
import { testimonials } from "../../../assets/mocks/testimonials";
import { respondTo } from "../../../styles/respondTo";
import { defaultTheme } from "../../../styles/theme";
import { BasicHeader } from "../../headers/basic/basic-header.styled";

interface TestimonialDotProps {
  active: boolean;
}

export const TestimonialsWrapper = styled.div`
  background-color: ${defaultTheme.colors.white};
  position: relative;
  padding: 36px 124px 186px 0;
  max-height: 760px;
  z-index: 0;
  overflow: hidden;
  color: #fff;

  ${respondTo.mobile`
    padding: ${defaultTheme.spacing.l} 0 280px;
  `};

  @media (max-width: 900px) {
    padding-left: 0px;
    padding-right: 0px;
  }

  @media screen and (max-width: 600px) {
    padding-bottom: 30px;
    padding-right: 0;
  }
`;
export const DescriptionContainer = styled.div`
  height: 550px;
  ${respondTo.desktopL`
  height: 600px;
  `}

  @media screen and (max-width: 600px) {
    height: 546px;
  }
`;

export const TestimonialsQuoteRight = styled.img`
  padding-left: 35px;
  height: 40%;
  z-index: 0;
  width: 20%;
  ${respondTo.tablet`
  margin: -10px -80px 0 125px;
`};
  ${respondTo.tablet`
  margin: -50px -180px 0 108px;
  opacity: 0.6;
`};
  ${respondTo.desktop1200`
  z-index: 1;
`};

  @media (max-width: 900px) {
    position: absolute;
    margin: 0;
    top: 100px;
    left: 12%;
  }
  @media (max-width: 768px) {
    left: 15%;
    top: 88px;
  }
  @media (max-width: 600px) {
    left: 10%;
    top: 146px;
  }
  @media (max-width: 570px) {
    left: 8%;
    top: 146px;
  }
  @media (max-width: 500px) {
    left: 3%;
    top: 90px;
  }
  @media (max-width: 430px) {
    left: 3%;
    top: 100px;
  }
  @media (max-width: 400px) {
    left: 4%;
  }
  @media (min-width: 360px) and (max-width: 375px) {
    margin: 0;
    position: absolute;
    top: 94px;
    padding: 0;
    left: 4%;
  }
  @media (max-width: 326px) {
    margin: 0;
    position: absolute;
    top: 77px;
    padding: 0;
    left: 9%;
    width: 15%;
  }
`;

export const TestimonialsQuoteLeft = styled.img`
  padding: ${defaultTheme.spacing.xs} 0;
  font-size: ${defaultTheme.fontSize.xxl};
  color: ${defaultTheme.colors.green};
  margin-right: 70%;
  z-index: 0;
  ${respondTo.tablet`
  margin-right: 90%;
`};

  ${respondTo.mobile`
  padding: 5px;
  margin-right: 85%;
  font-size: ${defaultTheme.fontSize.xl};
`};
`;

export const TestimonialsTitle = styled.div`
  mix-blend-mode: difference;
  margin: 30px 0 30px 75px;
  line-height: 130px;

  ${respondTo.tablet`
  display: flex;
  justify-content: center;
  margin: 0 0 30px 135px;
  line-height: 70px;
`};

  ${respondTo.mobile`
  margin: 0;
  line-height: 50px;
  `};

  @media (max-width: 900px) {
    margin-left: 0;
    margin-right: 0;
  }

  @media screen and (max-width: 326px) {
    justify-content: center;
    width: 100%;
    text-align: center;
    max-width: 100vw;
    overflow: hidden;
  }
`;

export const TestimonialContainer = styled.div`
  display: flex;
  flex-direction: column;
  background: ${defaultTheme.colors.mediumGrey};
  padding: 0px 40px;
  margin-right: -100px;
  transition: all 0.3s linear;
  justify-content: center;
  width: 50vw;
  z-index: 0;

  ${respondTo.mobile570`
  padding: 40px 20px;
  `};

  ${respondTo.tabletS`
  padding: 0px 35px;
  margin-right: -195px;
  width: 36vw;
`};
  ${respondTo.tablet`
  margin-right: -168px;
`};

  @media screen and (max-width: 900px) {
    width: initial;
    margin: 0;
    padding: 17px;
  }
`;

export const TestimonialsAuthorWraper = styled.div`
  border-left: 1px solid ${defaultTheme.colors.white};
  padding: 0 20px 20px 40px;
  z-index: 0;
  ${respondTo.tablet`
  border-left: 0;
  padding: 0 0 5px 0;
`};
  ${respondTo.mobile`
  padding-left: 12px;
`};
`;
export const TestimonialsAuthorName = styled.div`
  font-family: "Raleway";
  font-size: 36px;
  margin-bottom: 0.25rem;
  text-transform: uppercase;
  color: ${defaultTheme.colors.white};
  z-index: 0;
  ${respondTo.tabletS`
    font-size: 22px;
  `};
  ${respondTo.mobile326`
   font-size: 20px;
   font-weight: normal;
  `};
`;
export const TestimonialsAuthorJob = styled.div`
  margin-bottom: 0.2rem;
  color: ${defaultTheme.colors.white};
  font-size: 30px;
  font-weight: 350;
  letter-spacing: 0.15em
  z-index: 0;
  ${respondTo.tabletS`
    font-size: 18px;
  `};
  ${respondTo.mobile326`
  font-size: 16px;
 `};
`;

export const TestimonialsText = styled.div`
  border-left: 1px solid ${defaultTheme.colors.white};
  margin-bottom: 20px;
  padding: 0 50px 20px 40px;
  line-height: 32px;
  color: ${defaultTheme.colors.white};
  font-size: 18px;
  text-align: justify;
  z-index: 0;
  ${respondTo.desktop`
    line-height: 22px;
    font-size: 16px;
  `};
  ${respondTo.tablet`
    border-left: 0;
    font-size: 16px;
    padding: 0 0 20px 0;
  `};
  ${respondTo.tabletS`
    line-height: 16px;
    border-left: 0;
    font-size: 14px;
    padding: 0 0 20px 0;
  `};
  ${respondTo.mobile`
    font-size: 14px;
    padding: 10px;
    margin-bottom: 0;
  `};
  ${respondTo.mobileM`
    font-size: 12px;
    line-height: 14px;
    padding: 5px 12px 0px 12px;
  `};
  ${respondTo.mobile326`
    font-size: 10px;
  `};
`;

export const TestimonialsClutchImageContainer = styled.img`
  float: right;
  width: 40%;
  height: auto;
  z-index: 0;
  ${respondTo.mobile`
    width: 35%;
  `}
  ${respondTo.mobile326`
    width: 45%;
  `};
`;

export const TestimonialsNavigationWraper = styled.div`
  transform: translate(0, -175%);
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  /* grid-template-row: 1fr; */
  z-index: 0;
  ${respondTo.mobile`
    transform: translate(0, -100%);
  `}
`;

const TestimonialsArrowImage = styled.img`
  width: 50px;
  height: 80px;
  cursor: pointer;
  ${respondTo.tabletL`
  width: 30px;
  `};
`;

export const TestimonialsArrowLeft = styled(TestimonialsArrowImage)<{
  activeIndex: any;
}>`
  margin: auto;
  ${({ activeIndex }) => (activeIndex === 0 ? "opacity: 20%;" : "opacity: 100%;")}
  ${respondTo.mobile570`
  padding-left: 10px;
`};
  ${respondTo.mobileM`
  padding-left: 40px;
`};

  @media (max-width: 900px) {
    padding: 0;
    width: 30px;
  }
  @media screen and (max-width: 326px) {
    padding: 0;
    width: 20px;
  }
`;

export const TestimonialsArrowRight = styled(TestimonialsArrowImage)<{
  activeIndex: any;
}>`
  ${({ activeIndex }) => (activeIndex === testimonials.length - 1 ? "opacity: 20%;" : "opacity: 100%;")}
  padding-left: 10px;
  margin: auto;
  transform: translateX(160%);

  @media screen and (max-width: 900px) {
    transform: translateX(0%);
    padding: -6px;
    width: 30px;
  }

  @media screen and (max-width: 326px) {
    width: 20px;
  }
`;
export const TestimonialsArrowLeftContainer = styled.div`
  display: flex;
  position: relative;
  justify-content: center;
  z-index: 1;

  ${respondTo.desktop4k`
    right: 200px;
  `};
  ${respondTo.desktopHD`
    right: 200px;
  `};
  ${respondTo.desktopL`
    right: 160px;
  `};
  ${respondTo.desktop`
    right: 140px;
  `};
  ${respondTo.tabletL`
    right: 108px;
  `};
  ${respondTo.tablet900`
    padding: 0 160px 0 -1px;
  `};
  ${respondTo.tabletS`
    right: 80px;
    `};
  ${respondTo.tablet`
   right: 100px;
  `};

  @media (min-width: 280px) and (max-width: 900px) {
    margin: 0;
    position: static;
    padding: 0;
    padding-left: 0px;
    padding-right: 5px;
  }
`;
export const TestimonialsArrowRightContainer = styled.div`
  display: flex;
  position: relative;
  justify-content: center;
  background: rgba(105, 105, 105, 0.2);
  padding: 0 200px 0 0;
  right: -150px;
  z-index: 0;

  ${respondTo.tablet`
    left: 220px;
  `};
  ${respondTo.tablet900`
    right:-130px;
  `};
  ${respondTo.tabletS`
    right:-203px;
  `};

  @media screen and (max-width: 900px) {
    left: 0;
    right: 0;
    margin: 0;
    padding-left: 30px;
    padding-right: 15px;
    margin-left: 30px;
    background-color: rgba(79, 79, 79, 0.5);
  }
  @media screen and (max-width: 360px) {
    left: 0;
    right: 0;
    margin: 0;
    padding-left: 3px;
    padding-right: 53px;
    margin-left: 10px;
    padding-right: 15px;
    background-color: rgba(79, 79, 79, 0.5);
  }
`;
export const TestimonialDotWraper = styled.div`
  display: flex;
  position: relative;
  justify-content: space-between;
  left: 50%;
  transform: translateX(-50%);
  margin: auto auto;
  z-index: 0;
  ${respondTo.mobile`
  top: 7px;
  `};
`;

export const TestimonialDot = styled.div<TestimonialDotProps>`
  transition: 0.1s;
  width: 10px;
  height: 10px;
  margin: 0 5px;
  border-radius: 50%;
  background-color: ${({ active }) => (active ? `${defaultTheme.colors.green}` : `${defaultTheme.colors.lightGrey}`)};
  border: 2px solid ${defaultTheme.colors.white};
  cursor: pointer;
  z-index: 0;
  ${respondTo.mobile`
  margin:-15px 5px 0px;
  `}
`;

export const BackgroundImgTestimonialsWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`;

export const BackgroundImgTestimonialsMask = styled.div`
  margin: 0px 0px;
  position: absolute;
  width: 65%;
  max-height: 860px;
  right: 0;
  z-index: auto;

  ${respondTo.desktop`
    width: 62%;
  `};
  ${respondTo.desktopL`
    width: 65%;
  `};
  ${respondTo.desktop1200`
    width: 80%;
  `};
  ${respondTo.tablet`
    width: 100%;
  `};
  ${respondTo.mobile`
    height: 100%;
    width: 100%
  `};
`;
export const BackgroundImgTestimonialsContainer = styled.div`
  background-image: url(testimonials.svg);
  background-repeat: no-repeat;
  margin: 0px 0px;
  position: absolute;
  width: 46%;
  max-height: 860px;
  background-size: auto 100%;
  right: 0;
  z-index: auto;
  height: 100%;
  background-position: 50% 100%;
  background-color: #181818;

  @media (max-width: 1400px) {
    width: 100%;
  }
`;

export const TestimonialsHeader = styled(BasicHeader)`
  padding-bottom: 30px;
  padding-top: 30px;
  width: 50%;

  @media (max-width: 1400px) {
    width: 100%;
  }

  @media (max-width: 1000px) {
    margin-left: 0px;
    margin-right: 0px;
  }
`;
