import * as React from "react";

import { defaultTheme } from "../../../../styles/theme";
import { Align } from "../../../UI/Align";
import { Title } from "../../../UI/Title";
import { TechIcon } from "./TechIcon";
import {
  TechnologyTypeContainer,
  ContentContainer,
  TechIconsContainer,
  CircleImg,
  CircleTitle
} from "./TechnologyType.styled";

export interface TechnologyTypeProps {
  technology: {
    name: string;
    techs: { title: string; text: string; icon: string; hoverIcon: string }[];
  };
}

const circles = {
  Mobile: require("../../../../assets/images/newWebsite/tech1.png"),
  Frontend: require("../../../../assets/images/newWebsite/tech2.png"),
  Backend: require("../../../../assets/images/newWebsite/tech3.png")
};

export const TechnologyType = ({ technology }: TechnologyTypeProps) => {
  const [open, setOpen] = React.useState(false);
  const {
    colors: { textLight }
  } = defaultTheme;
  const currentCircle = circles[technology.name];

  return (
    <TechnologyTypeContainer>
      <CircleImg src={currentCircle} />
      <ContentContainer>
        <CircleTitle>{technology.name}</CircleTitle>
        <TechIconsContainer open={open}>
          {technology.techs.map((tech, index) => (
            <TechIcon tech={tech} technologyName={technology.name} zIndex={-index} />
          ))}
        </TechIconsContainer>
      </ContentContainer>
    </TechnologyTypeContainer>
  );
};
