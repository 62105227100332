import styled from "styled-components";
import { respondTo } from "../../../styles/respondTo";
import { defaultTheme } from "../../../styles/theme";
import { colors } from "../../../styles/colors";

interface DotProps {
  left?: boolean;
}

export const ClientsWrapper = styled.div`
  padding-bottom: ${defaultTheme.spacing.s};
`;

export const Dot = styled.div<DotProps>`
  position: absolute;
  top: -4px;
  left: ${({ left }) => (left ? 0 : "100%")};
  width: 10px;
  height: 10px;
  border-radius: ${defaultTheme.borderRadius.small};
  background: ${colors.geeksGreen};
`;

export const Line = styled.div`
  position: relative;
  flex-grow: 1;
  color: ${colors.geeksGreen};
  border-bottom: 1px solid ${colors.green};
`;

export const ClientTitle = styled.div`
  display: flex;
  align-items: center;
  padding-bottom: ${defaultTheme.spacing.l};
  font-size: ${defaultTheme.fontSize.xxxl};
  color: ${defaultTheme.colors.darkGrey};
  margin-top: ${defaultTheme.spacing.l};

  span {
    font-family: "Raleway";
    font-size: 64px;
    letter-spacing: 0.3em;
    text-indent: 0.3em;
    text-transform: uppercase;
    margin: 0 ${defaultTheme.spacing.xl};
    white-space: nowrap;
    ${respondTo.tablet`
    font-size:${defaultTheme.fontSize.xxxl};
  `};
    ${respondTo.mobile`
    font-size:${defaultTheme.fontSize.xl};
    margin:0 ${defaultTheme.spacing.xs};
  `};
    ${respondTo.mobileS`
    font-size:${defaultTheme.fontSize.m};
  `};
  }
`;

export const ClientLogoContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  ${respondTo.tablet`
    justify-content: space-around;
  `};

  ${respondTo.mobile`
    flex-direction: column;
    justify-content: center;
  `};
`;

export const ClientLogoImageContainer = styled.div`
  padding: ${defaultTheme.spacing.s} ${defaultTheme.spacing.l};

  ${respondTo.tabletS`
    padding: ${defaultTheme.spacing.xs}
  `};

  a {
    display: block;
  }
`;

export const ClientLogoImage = styled.img`
  width: 100%;
  height: auto;
  max-height: 60px;
  ${respondTo.tabletS`
    width: 70%;
    padding-left:${defaultTheme.spacing.l};
  `};

  ${respondTo.mobile`
    width: 60%;
  `};
`;
