import * as React from "react";
import { ActiveSectionContext } from "../common/context/active-context";

import { Layout } from "../components/layout";
import Metadata from "../components/layout/MetaData";
import { Contact } from "../components/sections/ContactUs/ContactUs";
import { Footer } from "../components/sections/Footer/Footer";
import { Home } from "../components/sections/Home/Home";
import { OurOffer } from "../components/sections/Offer/OurOffer";
import OurBadge from "../components/sections/OurBadge/OurBadge";
import { OurClients } from "../components/sections/OurClients/OurClients";
import { OurServices } from "../components/sections/OurServices/OurServices";
import { OurTeam } from "../components/sections/OurTeam/OurTeam";
import { Projects } from "../components/sections/Projects/Projects";
import { Technologies } from "../components/sections/Technologies/Technologies";
import { Testimonials } from "../components/sections/Testimonials/Testimonials";
import { OurProjects } from "../components/sections/OurProjects/OurProjects";
import { Workflow } from "../components/sections/Workflow/Workflow";
import { WebSection } from "../types/types";

const App = () => {
  const [activeSection, setActiveSection] = React.useState<WebSection>("home");

  const handleActiveSection = (id: WebSection): void => {
    setActiveSection(id);
  };

  return (
    <ActiveSectionContext.Provider
      value={{
        activeSectionId: activeSection,
        setActiveSectionId: handleActiveSection,
      }}
    >
      <Metadata title="Home" />
      <Layout>
        <Home id="home" />
        <OurServices id="services" />
        <Testimonials id="testimonials" />
        <OurClients id="clients" />
        <OurProjects id="ourProjects" />
        <Technologies id="technologies" />
        {/* <OurOffer id="offer" /> */}
        <Workflow id="workflow" />
        <OurTeam id="team" />
        <Contact id="contact" />
        <Footer />
      </Layout>
    </ActiveSectionContext.Provider>
  );
};

export default App;
