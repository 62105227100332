export interface CompanyData {
  name: string;
  details: CompanyDataDetails[];
}
export interface CompanyDataDetails {
  name: string;
  value: string;
}

export const companyData: CompanyData = {
  name: "Geeknauts Sp. z o.o.",
  details: [
    { name: "email", value: "contact@geeknauts.com" },
    { name: "phone", value: "(+48) 662-576-765" },
    { name: "address", value: "ul. Ulubiona 34" },
    { name: "", value: "Modlnica 32-085" }, // druga część adresu
    { name: "KRS", value: "0000362389" },
    { name: "NIP", value: "5130210402" },
    { name: "REGON", value: "121307472" }
  ]
};
