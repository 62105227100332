import styled from "styled-components";
import { fadeIn } from "../../../animations/animations";
import { respondTo } from "../../../styles/respondTo";
import { defaultTheme } from "../../../styles/theme";
import { colors } from "../../../styles/colors";

interface OurServiceProps {
  animate?: boolean;
}

export const ServiceTitle = styled.div<OurServiceProps>`
  border-top: solid 1px black;
  padding-top: 40px;
`;
export const ServicesInfo = styled.div<OurServiceProps>`
  animation-name: ${fadeIn};
  width: 100%;
  margin-bottom: ${defaultTheme.spacing.m};
`;
export const ServicesWrapper = styled.div`
  position: relative;
  padding: ${defaultTheme.spacing.l} 0;
  padding-top: ${defaultTheme.spacing.xl};
`;

export const OurServicesList = styled.div<OurServiceProps>`
  padding: 18px ${defaultTheme.spacing.s} 0px;
  ${respondTo.mobile`
    padding: 0 0;
  `};
`;
