import * as React from "react";
import styled from "styled-components";
import { defaultTheme } from "../../../../styles/theme";
import { respondTo } from "../../../../styles/respondTo";

const ContainerStyles = styled.div`
  position: relative;
  top: -77px;
  height: 1px;
  width: 100%;
  background: ${defaultTheme.colors.white};
  ${respondTo.mobile`
  display: none;
 `}
`;

const FillerStyles = styled.div<{
  completed: number;
}>`
  height: 100%;
  background: ${defaultTheme.colors.geeksGreen};
  text-align: right;
  width: ${({ completed }) => `${completed}%`};
  transition: width 0.3s linear;
  ::after {
    content: "";
    display: inline-block;
    position: relative;
    top: -9px;
    left: 6px;
    width: 10px;
    height: 10px;
    border-radius: ${defaultTheme.borderRadius.round};
    background: ${defaultTheme.colors.geeksGreen};
    z-index: 2;
    ${respondTo.mobile`
    width: 6px;
    height: 6px;
    left: 4px;
    top: -12px;
    `};
  }
`;

export const ProgressBarComponent = ({ completed }: { completed: number }) => {
  return (
    <ContainerStyles>
      <FillerStyles completed={completed}></FillerStyles>
    </ContainerStyles>
  );
};
