import React, { useState } from "react";
import { defaultTheme } from "../../../styles/theme";
import { Align } from "../../UI";

import {
  TeamMemberContainer,
  TeamLogo,
  TeamMemberImageContainer,
  TeamMemberRole,
  TeamMemberName,
  TeamMemberBorder
} from "./TeamMember.styled";

const borderWhite = require("../../../assets/images/team/frame_white.png");
const bordeGeeksGreen = require("../../../assets/images/team/frame_green.png");

interface TeamMemberProps {
  img: string;
  name: string;
  role: string;
  index: number;
}

export const TeamMember = ({ img, name, role, index }: TeamMemberProps) => {
  const [Border, setBorder] = useState(borderWhite);

  const getColorForRole = role => {
    switch (role) {
      case "CEO":
        return defaultTheme.colors.white;
      case "CTO":
        return defaultTheme.colors.white;
      case "COO":
        return defaultTheme.colors.white;
      default:
        return defaultTheme.colors.white;
    }
  };

  const changeBorder = bordeGeeksGreen => {
    setBorder(bordeGeeksGreen);
  };

  return (
    <TeamMemberContainer>
      <Align direction="column" justify="space-between" align="center">
        <TeamLogo>
          <TeamMemberImageContainer>
            <img src={img} width={170} />
          </TeamMemberImageContainer>
          <TeamMemberBorder
            src={Border}
            onMouseEnter={() => changeBorder(bordeGeeksGreen)}
            onMouseOut={() => changeBorder(borderWhite)}
          />
        </TeamLogo>
        <Align direction="column" justify="center" align="center">
          <TeamMemberName>{name}</TeamMemberName>
          <TeamMemberRole color={getColorForRole(role)}>{role}</TeamMemberRole>
        </Align>
      </Align>
    </TeamMemberContainer>
  );
};
