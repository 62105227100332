import * as React from "react";
import { defaultTheme } from "../../../styles/theme";
import { ArrowRightFooter, ArrowMobileFooter, ButtonWrapper } from "./ContactUs.styled";
import { Text } from "../../UI";
import { useText } from "../../../common/hooks";

interface SubmitButtonProps {
  onPress: () => void;
}

export const SubmitButton = ({ onPress }: SubmitButtonProps) => {
  const arrow = require("../../../assets/images/footer/arrow-right-footer.svg") as string;
  const arrow2 = require("../../../assets/images/footer/arrow-mobile-footer.svg") as string;
  const { text } = useText();

  return (
    <ButtonWrapper onClick={onPress}>
      <Text size={"large"} color={defaultTheme.colors.textLight}>
        {text("contact.submitButton")}
      </Text>
      <ArrowRightFooter>
        <img src={arrow}></img>
      </ArrowRightFooter>
      <ArrowMobileFooter>
        <img src={arrow2} height="10"></img>
      </ArrowMobileFooter>
    </ButtonWrapper>
  );
};
