import styled from "styled-components";
import { respondTo } from "../../../../styles/respondTo";
import { defaultTheme } from "../../../../styles/theme";

export const CompanyContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  ${respondTo.desktop1200`
  width:300px;
  `}
`;

export const InfomationRow = styled.div`
  display: flex;
  width: 100%;
  margin-top: 16px;
`;

export const InfomationRowValue = styled.div`
  @media (max-width: 360px) {
    margin-right: auto;
  }
`;
export const InfomationRowName = styled.div`
  width: 120px;
`;

export const LogoFooter = styled.img`
  width: 260px;
  margin-bottom: 70px;
  margin-top: 30px;

  ${respondTo.tabletL`
  width:200px;
  align-self: center;
  `}
  ${respondTo.mobile`
  width:170px;
  `}
`;
