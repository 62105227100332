import styled from "styled-components";
import { respondTo } from "../../styles/respondTo";
import { defaultTheme } from "../../styles/theme";

interface Navbar {
  clicked?: boolean;
}

interface NavItemProps {
  active: boolean;
  name: string;
}

export const NavContainer = styled.div`
  position: fixed;
  background-color: ${defaultTheme.colors.brandBlack};
  width: 100vw;
  height: 80px;
  z-index: 5;
`;

export const NavIcon = styled.img`
  margin-left: 20px;
  height: 4.5vw;
  min-height: 70px;
  max-height: 75px;

  ${respondTo.mobile`
    width:110px;
    height:55px;
    padding:8px;
  `}

  ${respondTo.tablet`
    height: 8.5vw;
  `}
`;

export const MobileIcon = styled.div<Navbar>`
  display: none;
  align-self: center;

  ${respondTo.tablet`
     display: block;
     padding-right: ${defaultTheme.spacing.l};
     font-size: ${defaultTheme.fontSize.xl};
     cursor: pointer;
     z-index:4;
     color:${({ clicked }) => (clicked ? `${defaultTheme.colors.lightGrey}` : `${defaultTheme.colors.lightGrey}`)};
  `}
`;

export const NavMenu = styled.div<Navbar>`
  display: flex;
  align-items: center;
  min-width: 75%;
  list-style: none;
  z-index: 3;
  height: 100%;

  ${respondTo.desktop`
    width:100%;
`};

  ${respondTo.tablet`
     display: flex;
     flex-direction: column;
     justify-content: center;
     text-align:center;
    //  width: 100%;
     height: 100vh;
     position: absolute;
     top: 0px;
     left: ${({ clicked }) => (clicked ? 0 : "-100%")};
     opacity: 1;
     transition: all 0.5s ease;
     background: ${defaultTheme.colors.brandBlack};
  `}
`;

export const NavItem = styled.li<NavItemProps>`
  color: ${({ active }) => (active ? defaultTheme.colors.geeksGreen : defaultTheme.colors.lightWhite)};
  display: flex;
  font-size: 16px;
  font-weight: 150;
  cursor: pointer;
  text-align: center;
  letter-spacing: 1px;
  margin: 0;
  margin-right: 60px;
  font-family: "Raleway";

  &:hover {
    color: ${defaultTheme.colors.geeksGreen};
  }

  ${respondTo.tablet` 
    margin-top: 10px;
    min-width:70%;
    margin:0 auto;
    text-align:left;
    padding: ${defaultTheme.spacing.m} 0px;
    color:${defaultTheme.colors.lightGrey};
    font-size:${defaultTheme.fontSize.xxxl};
  `}

  ${respondTo.desktopS` 
    margin-right: 15px;
    font-size:${defaultTheme.fontSize.s};
  `}
 
  ${respondTo.desktop`
    font-size:${defaultTheme.fontSize.s}; 
    margin-right: 25px;
  `}

    
  ${respondTo.desktop1200` 
    margin-right: 50px;
    font-size:${defaultTheme.fontSize.xs};
  `}

  ${respondTo.desktopL` 
    margin-right: 50px;
  `}
  
  ${respondTo.mobile`
    width:90%;
    font-size:${defaultTheme.fontSize.xxl};
  `}
`;

export const MobileSwitch = styled.div`
  display: none;

  ${respondTo.tablet`
     display: block;
     padding-right: ${defaultTheme.spacing.s};
  `}
`;

export const DesktopSwitch = styled.div`
  display: block;
  margin-right: ${defaultTheme.spacing.m};

  ${respondTo.tablet`
     display: none;
  `}
`;
