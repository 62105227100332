import styled from "styled-components";
import { fadeIn } from "../../../animations/animations";
import { respondTo } from "../../../styles/respondTo";
import { defaultTheme } from "../../../styles/theme";
import { colors } from "../../../styles/colors";

interface OurProjectsProps {
  animate?: boolean;
}

interface DotProps {
  left?: boolean;
}
export const OurProjectsWrapper = styled.div`
  margin-top: ${defaultTheme.spacing.l};
  padding: ${defaultTheme.spacing.base} ${defaultTheme.spacing.xl};
  overflow: hidden;

  @media (max-width: 606px) {
    padding: ${defaultTheme.spacing.base} ${defaultTheme.spacing.s};
    margin-top: 0;
  }
`;

export const CardWrapper = styled.div``;

export const Title = styled.div`
  position: relative;
  font-size: 104px;
  text-transform: uppercase;
  letter-spacing: 30px;
  text-align: center;
  ${respondTo.tabletL`
  font-size: 8vw;
  letter-spacing: 2vw;
  `}
`;

export const TitleLine = styled.img`
  display: flex;
  position: absolute;
  flex-grow: 1;
  top: 50%;
  left: -40%;
`;
export const OurProjectsTitle = styled.div<OurProjectsProps>`
  border-top: solid 1px black;
  padding-top: 40px;
`;
export const OurProjectsInfo = styled.div<OurProjectsProps>`
  animation-name: ${fadeIn};
`;

export const OurProjectsList = styled.div<OurProjectsProps>`
  justify-content: center;

  ${respondTo.tabletL`
    display: none;
  `}
`;
export const OurProjectsListMobile = styled(OurProjectsList)`
  padding: 0px ${defaultTheme.spacing.s};
  display: none;

  ${respondTo.tabletL`
    display: block;
  `}
`;

export const SubpageTitleWrapper = styled.div``;

export const OurProjectsSeparator = styled.div<{
  noMarginBottom?: boolean;
}>`
  height: 1px;
  background-color: ${defaultTheme.colors.black};
  width: 65%;
  margin-top: 48px;
  margin-bottom: ${({ noMarginBottom }) => (noMarginBottom ? "0px" : "48px")};

  ${respondTo.tabletL`
    width: 100%;
  `}
`;

export const ProjectSubpage = styled.div`
  padding: ${defaultTheme.spacing.base};
  font-family: "Raleway";
  font-size: 36px;
  letter-spacing: 10px;
  font-weight: 300;
  margin: ${defaultTheme.spacing.xs};
  cursor: pointer;
  &:hover {
    color: ${defaultTheme.colors.geeksGreen};
  }
  ${respondTo.tablet`
   font-size: ${defaultTheme.fontSize.xl};
  `}
  ${respondTo.mobile`
   font-size: ${defaultTheme.fontSize.m};
  `}
`;

export const SubpageTitle = styled.div`
  display: flex;
  font-size: 36px;
  font-family: "Raleway";
  font-weight: 300;
  justify-content: center;
  white-space: nowrap;
  text-transform: uppercase;

  @media (max-width: 1030px) {
    letter-spacing: 1px;
    font-size: ${defaultTheme.fontSize.font26};
  }

  @media (max-width: 570px) {
    font-size: ${defaultTheme.fontSize.l};
  }

  @media (max-width: 470px) {
    font-size: ${defaultTheme.fontSize.m};
  }

  &::before {
    content: "+";
    color: ${defaultTheme.colors.geeksGreen};
  }

  &::after {
    content: "->";
    display: inline-block;
    margin-left: 10px;
  }

  .highlighted {
    color: ${defaultTheme.colors.geeksGreen};
  }
`;
