import styled, { css } from "styled-components";
import { defaultTheme } from "../../../../styles/theme";
import { respondTo } from "../../../../styles/respondTo";

export const ProgressBarandOrbitContainer = styled.div`
  ${respondTo.tablet`
  transform:scale(0.7);
  `};
  ${respondTo.tabletS`
  transform:scale(0.6);
  `};
`;

export const WorkflowContinuationBarContainer = styled.div``;
export const ImageContainer = styled.div`
  display: flex;
  position: relative;
  justify-content: center;
  ${respondTo.mobile`
  display: none;
 `}
`;
export const WorkflowIconImage = styled.img`
  display: flex;
  position: relative;
  margin: ${defaultTheme.spacing.l} 100px;
  top: 22px;
  width: 40px;
  transform: scale(2);
  ${respondTo.desktopL`
    margin: ${defaultTheme.spacing.l} 70px;
    `};
`;
export const StepContainer = styled.div`
  display: flex;
  position: relative;
  align-items: center;
  flex-direction: column;
  top: 10px;
  font-size: ${defaultTheme.fontSize.xl};
  color: ${defaultTheme.colors.white};
  cursor: pointer;
`;
export const StepTitle = styled.p<{
  greenColor: boolean;
}>`
  color: ${({ greenColor }) => (greenColor ? `${defaultTheme.colors.geeksGreen}` : `${defaultTheme.colors.white}`)};
`;
export const StepBorder = styled.div<{
  shouldLeftBorderBeDisplayed: boolean;
  borderColor: string;
}>`
  position: relative;
  width: 100%;
  top: -38.3%;
  left: 1px;
  height: 30px;
  ${({ shouldLeftBorderBeDisplayed, borderColor }) =>
    css`
      border-right: 2px solid ${borderColor};
      ${shouldLeftBorderBeDisplayed ? `border-left: 2px solid ${borderColor};` : ""}
    `}
`;
