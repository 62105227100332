import styled, { css } from "styled-components";
import { keyframes } from "styled-components";
import { defaultTheme } from "../../../../styles/theme";
import { respondTo } from "../../../../styles/respondTo";

const rotateLaunch = keyframes`
  from {
    transform: rotate(70deg);
  }

  to {
    transform: rotate(430deg);
  }
`;
const rotateProduct = keyframes`
  from {
    transform: rotate(130deg);
  }

  to {
    transform: rotate(490deg);
  }
`;
const rotateUIUX = keyframes`
  from {
    transform: rotate(190deg);
  }

  to {
    transform: rotate(550deg);
  }
`;
const rotateSolution = keyframes`
  from {
    transform: rotate(250deg);
  }

  to {
    transform: rotate(610deg);
  }
`;
const rotateBrainstorm = keyframes`
  from {
    transform: rotate(220deg);
  }

  to {
    transform: rotate(580deg);
  }
`;

const rotatedot = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(-360deg);
  }
`;

export const OrbitAndLeftContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  ${respondTo.desktopL`
  transform:scale(0.9);
  `};
  ${respondTo.desktop1200`
  transform:scale(0.8);
  `};
  ${respondTo.tablet`
  transform:scale(0.7);
  margin: -100px;
  `};
  ${respondTo.tablet900`
  transform:scale(0.6);
  `};
  ${respondTo.tabletS`
  transform:scale(0.5);
  margin-bottom: -150px;
  margin-top: -100px;
  `};
  ${respondTo.mobile`
   display: flex;
   flex-direction: column-reverse;
   align-items: center;
   transform: scale(0.6);
   margin: -380px 0;
  `};
`;

export const LeftContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  ${respondTo.mobile`
  margin-top: -150px;
  `}
`;
export const StepSubTitleAndTitleContainer = styled.div`
  display: flex;
  flex-direction: row;
`;
export const SubtitleAndTitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: end;
  margin-left: 14px;
`;
export const StepContainer = styled.div`
  display: flex;
  color: ${defaultTheme.colors.geeksGreen};
  font-size: 76px;
  line-height: 58px;
  margin: 0;
  letter-spacing: 10px;
`;
export const SubtitleContainer = styled.div`
  position: relative;
  font-size: 18px;
  margin: 0;
  color: ${defaultTheme.colors.geeksGreen};
`;
export const TitleContainer = styled.div`
  display: flex;
  font-size: 36px;
  margin: 0;
  letter-spacing: 10%;
  text-transform: uppercase;
  color: ${defaultTheme.colors.geeksGreen};
`;
export const TextContainer = styled.p`
  position: relative;
  font-size: 20px;
  color: ${defaultTheme.colors.white};
  width: 400px;
  height: 100px;
  margin: 16px auto;
`;

const setLeft = (activeIndex: number) => {
  switch (activeIndex) {
    case 4:
      return "23%";
    case 3:
      return "18%";
    default:
      return "22%";
  }
};
const setTransform = (activeIndex: number) => {
  switch (activeIndex) {
    case 4:
      return "";
    case 3:
      return "scale(1)";
    default:
      return "scale(1.3)";
  }
};

export const BackgroundImageContainer = styled.div<{
  orbitIcon: any;
  activeIndex: number;
}>`
  position: relative;
  height: 350px;
  top: 10%;
  left: -26.5%;
  background-image: url(${({ orbitIcon }) => orbitIcon});
  background-repeat: no-repeat;
  opacity: 40%;
  ${respondTo.mobile`
  margin-top: 150px;
  height: 350px;
  opacity: 70%;
  left: ${({ activeIndex }) => setLeft(activeIndex)};
  transform: ${({ activeIndex }) => setTransform(activeIndex)};
  
  `};
`;

export const OrbitContainer = styled.div<{
  activeIndex: number;
  t: any;
}>`
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  margin-top: 40px;
  transform: scale(0.9);
  ${respondTo.mobile`
  padding-top: 210px;
  `};
  ${respondTo.mobile326`
  transform: scale(0.8);
  `};

  .launch {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    height: 570px;
    width: 570px;
    left: -5px;
    border: 1px solid ${defaultTheme.colors.geeksGreen};
    transform: rotate(70deg);
    ${({ activeIndex }) =>
      css`
        animation: ${activeIndex === 4 ? css`2s linear ${rotateLaunch}` : "none"};
      `};
  }
  .dotlaunch {
    font-family: "Raleway";
    position: relative;
    left: -10%;
    width: 10px;
    height: 10px;
    background: ${defaultTheme.colors.white};
    border-radius: 50%;
    ${({ activeIndex }) =>
      css`
        animation: ${activeIndex === 4 ? css`2s linear ${rotatedot}` : "none"};
      `};

    ::after {
      ${({ t }) => `content: "${t("workflow.steps.fifth.title")}";`}
      display: inline-block;
      color: ${defaultTheme.colors.geeksGreen};
      font-size: 14px;
      position: relative;
      top: 0px;
      transform: rotate(289deg);

      ${({ activeIndex }) => (activeIndex === 4 ? "opacity: 100%;" : "opacity: 0%;")}
    }
    ${({ activeIndex }) =>
      activeIndex === 4
        ? css`
            background: ${defaultTheme.colors.geeksGreen};
          `
        : css`
            background: ${defaultTheme.colors.white};
            opacity: 50%;
          `};
  }
  .product {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    height: 454px;
    width: 454px;
    border-radius: 50%;
    left: -5px;
    border: 1px solid ${defaultTheme.colors.geeksGreen};
    transform: rotate(130deg);

    ${({ activeIndex }) =>
      css`
        animation: ${activeIndex === 3 ? css`2s linear ${rotateProduct}` : "none"};
      `};
  }
  .dotproduct {
    position: relative;
    left: -6%;
    width: 10px;
    height: 10px;
    background: ${defaultTheme.colors.white};
    border-radius: 50%;
    ${({ activeIndex }) =>
      css`
        animation: ${activeIndex === 3 ? css`2s linear ${rotatedot}` : "none"};
      `};

    ::after {
      ${({ t }) => `content: "${t("workflow.steps.fourth.title")}";`}
      display: inline-block;
      color: ${defaultTheme.colors.geeksGreen};
      font-size: 14px;
      position: relative;
      top: -22px;
      left: -27px;
      transform: rotate(160deg);
      ${({ activeIndex }) => (activeIndex === 3 ? "opacity: 100%;" : "opacity: 0%;")}
    }

    ${({ activeIndex }) =>
      activeIndex === 3
        ? css`
            background: ${defaultTheme.colors.geeksGreen};
          `
        : css`
            background: ${defaultTheme.colors.white};
            opacity: 50%;
          `};
  }
  .uiux {
    display: flex;
    position: relative;
    align-items: center;
    justify-content: center;
    height: 400px;
    width: 400px;
    border-radius: 50%;
    left: -5px;
    border: 1px solid ${defaultTheme.colors.geeksGreen};
    transform: rotate(190deg);
    ${({ activeIndex }) =>
      css`
        animation: ${activeIndex === 2 ? css`2s linear ${rotateUIUX}` : "none"};
      `};
  }
  .dotuiux {
    position: relative;
    left: -13.5%;
    width: 10px;
    height: 10px;
    background: ${defaultTheme.colors.white};
    border-radius: 50%;
    ${({ activeIndex }) =>
      css`
        animation: ${activeIndex === 2 ? css`2s linear ${rotatedot}` : "none"};
      `};
    ::after {
      ${({ t }) => `content: "${t("workflow.steps.third.title")}";`}
      display: inline-block;
      color: ${defaultTheme.colors.geeksGreen};
      font-size: 14px;
      position: relative;
      top: 13px;
      left: -9px;
      transform: rotate(330deg);
      ${({ activeIndex }) => (activeIndex === 2 ? "opacity: 100%;" : "opacity: 0%;")}
    }
    ${({ activeIndex }) =>
      activeIndex === 2
        ? css`
            background: ${defaultTheme.colors.geeksGreen};
          `
        : css`
            background: ${defaultTheme.colors.white};
            opacity: 50%;
          `}
  }
  .solution {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    height: 292px;
    width: 292px;
    left: -5px;
    border-radius: 50%;
    border: 1px solid ${defaultTheme.colors.geeksGreen};
    transform: rotate(250deg);
    ${({ activeIndex }) =>
      css`
        animation: ${activeIndex === 1 ? css`2s linear ${rotateSolution}` : "none"};
      `};
    .dotsolution {
      position: relative;
      width: 10px;
      height: 10px;
      left: -20.2%;
      background: ${defaultTheme.colors.white};
      border-radius: 50%;
      ${({ activeIndex }) =>
        css`
          animation: ${activeIndex === 1 ? css`2s linear ${rotatedot}` : "none"};
        `};
      ::after {
        ${({ t }) => `content: "${t("workflow.steps.second.title")}";`}
        display: inline-block;
        color: ${defaultTheme.colors.geeksGreen};
        font-size: 14px;
        position: relative;
        height: 20px;
        width: 80px;
        left: -57px;
        transform: rotate(82deg);
        ${({ activeIndex }) => (activeIndex === 1 ? "opacity: 100%;" : "opacity: 0%;")}
      }
      ${({ activeIndex }) =>
        activeIndex === 1
          ? css`
              background: ${defaultTheme.colors.geeksGreen};
            `
          : css`
              background: ${defaultTheme.colors.white};
              opacity: 50%;
            `}
    }
    .brainstorm {
      display: flex;
      position: relative;
      align-items: center;
      justify-content: center;
      height: 174px;
      width: 174px;
      border: 1px solid ${defaultTheme.colors.geeksGreen};
      border-radius: 50%;
      left: -5px;
      transform: rotate(220deg);
      ${({ activeIndex }) =>
        css`
          animation: ${activeIndex === 0 ? css`2s linear ${rotateBrainstorm}` : "none"};
        `};
    }
    .dotbrainstorm {
      font-family: "Raleway";
      position: relative;
      width: 10px;
      height: 10px;
      left: -39.5%;
      background: ${defaultTheme.colors.white};
      border-radius: 50%;
      ${({ activeIndex }) =>
        css`
          animation: ${activeIndex === 0 ? css`2s linear ${rotatedot}` : "none"};
        `};
      ::after {
        ${({ t }) => `content: "${t("workflow.steps.first.title")}";`}
        display: inline-block;
        color: ${defaultTheme.colors.geeksGreen};
        font-size: 14px;
        height: 20px;
        width: 100px;
        position: relative;
        top: -35px;
        left: -43px;
        transform: rotate(220deg);
        ${({ activeIndex }) => (activeIndex === 0 ? "opacity: 100%;" : "opacity: 0%;")}
      }
      ${({ activeIndex }) =>
        activeIndex === 0
          ? css`
              background: ${defaultTheme.colors.geeksGreen};
            `
          : css`
              background: ${defaultTheme.colors.white};
              opacity: 50%;
            `};
    }

    .circle {
      position: relative;
      width: 37px;
      height: 37px;
      left: -5px;
      z-index: 5;
      border-radius: 50%;
      opacity: 50%;
      background: ${defaultTheme.colors.black};
      background-color: ${defaultTheme.colors.black};
      box-shadow: 0 0 60px 30px #b5e7a0, 0 0 100px 60px #b5e7a0, 0 0 140px 90px #b5e7a0;
    }
  }
`;
export const StyledArrow = styled.div`
  display: none;

  ${respondTo.mobile`
  display: inline;
  position: absolute;
  top: calc(50% - 80px);
  cursor: pointer;
  `}
`;

export const StyledArrowNext = styled(StyledArrow)<{
  activeIndex: any;
}>`
  top: 70%;
  right: -10%;
  ${respondTo.mobileM`
  right: 0%;
  `}
  ${respondTo.mobile360`
  right: 9%;
  `}
  ${({ activeIndex }) => (activeIndex === 4 ? "opacity: 20%;" : "opacity: 100%;")}
`;

export const StyledArrowPrev = styled(StyledArrow)<{
  activeIndex: any;
}>`
  top: 70%;
  left: -8%;
  ${respondTo.mobileM`
  left: 0%;
  `};
  ${respondTo.mobile360`
  left: 8%;
  `}
  ${({ activeIndex }) => (activeIndex === 0 ? "opacity: 20%;" : "opacity: 100%;")}
`;

export const ArrowImage = styled.img`
  width: 40px;
`;
