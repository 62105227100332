import * as React from "react";
import scrollToElement from "scroll-to-element";

import { useIntl } from "gatsby-plugin-react-intl";

import { Carousel } from "./Carousel/Carousel";

import {
  HomeWrapper,
  SkyImg,
  HomeImgContainer,
  ButtonContainer,
  HomeTextContainer,
  ContactSpan,
  SocialButtonsContainer,
  SocialButton,
  StartLineContainer,
  EndLineContainer,
  LineImg,
  Dot,
  LightContainer,
  LightImg,
  FirstTextContainer,
  TextContainer,
  BannerWraper
} from "./Home.styled";

import { Title } from "../../UI";
import { useActiveSection, useResize, useText } from "../../../common/hooks";
import { ActiveSectionContext } from "../../../common/context/active-context";
import { WebSectionProps } from "../../../types/types";
import { colors } from "../../../styles/newColors";
import { durations } from "../../../animations/animations";

const light = require("../../../assets/images/newWebsite/light.webp");
const sky = require("../../../assets/images/newWebsite/headerIllustration.svg");
const skyMobile = require("../../../assets/images/newWebsite/header.svg");
const socials = [
  require("../../../assets/images/newWebsite/socialMedia/clutch.png"),
  require("../../../assets/images/newWebsite/socialMedia/instagram.png"),
  require("../../../assets/images/newWebsite/socialMedia/linkedin.png")
];

const links = [
  "https://clutch.co/profile/geeknauts",
  "https://instagram.com/geeknauts",
  "https://www.linkedin.com/company/geeknauts/"
];

export const Home = ({ id }: WebSectionProps) => {
  const { setActiveSectionId } = React.useContext(ActiveSectionContext);
  const sectionRef = React.useRef<HTMLDivElement>(null);

  const intl = useIntl();
  const { text } = useText();
  const {
    elementSize: { height }
  } = useResize(sectionRef.current!);

  useActiveSection(sectionRef, setActiveSectionId, id, height);

  const carouselwords = text([
    "home.carousel.create",
    "home.carousel.design",
    "home.carousel.develop",
    "home.carousel.host",
    "home.carousel.launch",
    "home.carousel.maintain"
  ]);

  return (
    <HomeWrapper ref={sectionRef}>
      <LightContainer>
        <LightImg src={light} />
      </LightContainer>
      <BannerWraper>
        <HomeImgContainer>
          <SkyImg src={skyMobile} alt="sky" />
        </HomeImgContainer>
        <HomeTextContainer data-sal="slide-up" data-sal-duration={durations.slow} data-sal-easing="ease-in-out">
          <Title size="header" color={colors.textLight} bold>
            /*
          </Title>
          {intl.locale === "en" && (
            <Title size="header" color={colors.textLight} bold>
              <TextContainer>{text("home.title.first")}</TextContainer>
            </Title>
          )}
          <Carousel words={carouselwords} />

          <Title size="header" color={colors.textLight} bold>
            {text("home.title.second")} */
          </Title>
        </HomeTextContainer>
      </BannerWraper>
      <ButtonContainer data-sal="slide-right" data-sal-duration={durations.slow} data-sal-easing="ease-in-out">
        <StartLineContainer>
          <LineImg />
        </StartLineContainer>
        <ContactSpan
          onClick={() => {
            scrollToElement("#contact", {
              offset: -60,
              ease: "out-circ",
              duration: 1000
            });
          }}
        >
          {text("home.button")}
        </ContactSpan>
        <EndLineContainer>
          <LineImg />
          <Dot />
        </EndLineContainer>
        <SocialButtonsContainer>
          {socials.map((icon, index) => (
            <SocialButton src={icon} onClick={() => window.open(links[index])} />
          ))}
        </SocialButtonsContainer>
      </ButtonContainer>
    </HomeWrapper>
  );
};
