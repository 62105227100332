const brainGreen = require("../images/workflowSteps/brainGreen.svg");
const brainWhite = require("../images/workflowSteps/brainWhite.svg");
const lightbulbGreen = require("../images/workflowSteps/lightbulbGreen.svg");
const lightbulbWhite = require("../images/workflowSteps/lightbulbWhite.svg");
const wheelsGreen = require("../images/workflowSteps/wheelsGreen.svg");
const wheelsWhite = require("../images/workflowSteps/wheelsWhite.svg");
const uiuxGreen = require("../images/workflowSteps/uiuxGreen.svg");
const uiuxWhite = require("../images/workflowSteps/uiuxWhite.svg");
const rocketGreen = require("../images/workflowSteps/rocketGreen.svg");
const rocketWhite = require("../images/workflowSteps/rocketWhite.svg");
const brainGreenOrbit = require("../images/workflowSteps/brainGreenOrbit.svg");
const lightbulbGreenOrbit = require("../images/workflowSteps/lightbulbGreenOrbit.svg");
const uiuxGreenOrbit = require("../images/workflowSteps/uiuxGreenOrbit.svg");
const wheelsGreenOrbit = require("../images/workflowSteps/wheelsGreenOrbit.svg");
const rocketGreenOrbit = require("../images/workflowSteps/rocketGreenOrbit.svg");

export const ICONS = {
  brain: {
    white: brainWhite,
    green: brainGreen
  },
  lightbulb: {
    white: lightbulbWhite,
    green: lightbulbGreen
  },
  wheels: {
    white: wheelsWhite,
    green: wheelsGreen
  },
  uiux: {
    white: uiuxWhite,
    green: uiuxGreen
  },
  rocket: {
    white: rocketWhite,
    green: rocketGreen
  }
};
export const STEPS = [
  {
    index: 0,
    step: "I.",
    icon: "brain",
    title: "workflow.steps.first.title",
    subtitle: "workflow.steps.first.subtitle",
    description: "workflow.steps.first.text",
    orbitIcon: brainGreenOrbit
  },
  {
    index: 1,
    step: "II.",
    icon: "lightbulb",
    title: "workflow.steps.second.title",
    subtitle: "workflow.steps.second.subtitle",
    description: "workflow.steps.second.text",
    orbitIcon: lightbulbGreenOrbit
  },
  {
    index: 2,
    step: "III.",
    icon: "uiux",
    title: "workflow.steps.third.title",
    subtitle: "workflow.steps.third.subtitle",
    description: "workflow.steps.third.text",
    orbitIcon: uiuxGreenOrbit
  },
  {
    index: 3,
    step: "IV.",
    icon: "wheels",
    title: "workflow.steps.fourth.title",
    subtitle: "workflow.steps.fourth.subtitle",
    description: "workflow.steps.fourth.text",
    orbitIcon: wheelsGreenOrbit
  },
  {
    index: 4,
    step: "V.",
    icon: "rocket",
    title: "workflow.steps.fifth.title",
    subtitle: "workflow.steps.fifth.subtitle",
    description: "workflow.steps.fifth.text",
    orbitIcon: rocketGreenOrbit
  }
];
