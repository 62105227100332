import styled from "styled-components";
import { respondTo } from "../../../styles/respondTo";
import { defaultTheme } from "../../../styles/theme";
import { fadeIn } from "../../../animations/animations";
interface CardProps {
  small?: boolean;
}

export const OurProjectCard = styled.div<CardProps>`
  position: relative;

  ${respondTo.tabletL`
    border-bottom: none;
  `}
`;

export const NewHoverContainer = styled.div`
  color: ${defaultTheme.colors.black};
  width: 100%;
  opacity: 0;
  transition-duration: 0.15s;
  position: absolute;
  z-index: 2;
  top: 0px;

  &:hover {
    opacity: 100%;
  }
`;

export const HoverContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  position: relative;
  animation-name: ${fadeIn};
  cursor: pointer;
  width: 100%;
  overflow: hidden;

  ${respondTo.tabletL`
    opacity: 1;
  `}
`;
export const MobileContainer = styled.div`
  display: none;
  height: 200px;

  ${respondTo.tabletL`
  display: inline;
  height: 200px;
  `}
  ${respondTo.mobile`
  padding: 0 30px;
  `}
  ${respondTo.mobileM`
  height: 160px;
  `}
  ${respondTo.mobile326`
  padding: 0 60px;
  `}
`;

export const OurProjectImg = styled.img<CardProps>`
  object-fit: cover;
  z-index: 1;
  width: 100%;
  height: 10rem;
  transition: all 0.3s ease-in;
`;

export const OurProjectCardWrapper = styled.div<CardProps>`
  width: ${({ small }) => (small ? "calc(25% - 30px)" : "calc(33.333% - 30px)")};
  max-width: 500px;

  &:hover ${OurProjectImg} {
    transform: scale(1.2);
  }

  ${respondTo.tabletL`
    width: 100%;
    margin-left: auto;
    margin-right: auto;
  `}
`;

export const CardTitle = styled.div<CardProps>`
  margin: ${defaultTheme.spacing.xxs} 0 ${defaultTheme.spacing.xxs} 0;
  font-size: ${defaultTheme.fontSize.font26};
  letter-spacing: 5px;
  text-transform: uppercase;
  text-align: center;

  @media (min-width: 1031px) and (max-width: 1600px) {
    font-size: ${defaultTheme.fontSize.font18};
    white-space: nowrap;
    letter-spacing: 2px;
  }
  @media (max-width: 606px) {
    letter-spacing: 1px;
    margin-bottom: ${defaultTheme.spacing.small};
  }

  @media (max-width: 430px) {
    font-size: ${defaultTheme.fontSize.m};
    white-space: nowrap;
  }
`;

export const HoverImg = styled.img<CardProps>`
  object-fit: cover;
  height: 250px;
  ${respondTo.desktop`
  width: 90%;
  margin-left: 20px;
  `}
  ${respondTo.tabletL`
display:none;
`};
`;

export const ProjectLogo = styled.img<CardProps>`
  padding: 80px 0px 90px 0px;
  background: rgba(130, 183, 60, 0.8);
  width: 100%;
  height: ${({ small }) => (small ? "10vw" : "11vw")};
  max-height: 200px;
  position: absolute;
  mix-blend-mode: lighten;
  left: 50%;

  transform: translate(-50%, -108%);

  ${respondTo.desktop`
  transform: ${({ small }) => (small ? "translate(-50%, -95%)" : "translate(-50%, -100%)")};
  `}

  ${respondTo.tabletL`
  display:none;
  background: none;
  `};

  ${respondTo.tablet`
  font-size: ${({ small }) => (small ? "22px" : "38px")}; 
  `};
`;

export const CardTags = styled.div`
  text-align: center;
  font-size: 1.2rem;

  @media (min-width: 1031px) and (max-width: 1600px) {
    font-size: 0.7rem;
    white-space: nowrap;
  }
  @media (max-width: 606px) {
    font-size: 1rem;
  }
  @media (max-width: 430px) {
    font-size: 0.7rem;
  }
`;

export const CardTag = styled.span`
  display: inline-block;
  text-transform: uppercase;

  &::after {
    content: "•";
    display: inline-block;
    margin-left: ${defaultTheme.spacing.xs};
    margin-right: ${defaultTheme.spacing.xs};
    @media (min-width: 1031px) and (max-width: 1600px) {
      margin-left: ${defaultTheme.spacing.base};
      margin-right: ${defaultTheme.spacing.base};
    }
  }

  &:last-child::after {
    display: none;
  }
`;
