import React from "react";
import { durations } from "../../../animations/animations";
import { defaultTheme } from "../../../styles/theme";
import { WebSectionProps } from "../../../types/types";
import { Align, Section, Title } from "../../UI";
import { TeamCarousel } from "../../TeamCarousel/TeamCarousel";
import {
  TeamMembersContainer,
  OurTeamSectionDivider,
} from "./OurTeam.styles";
import { team } from "../../../assets/mocks/team";
import { useActiveSection, useResize, useText } from "../../../common/hooks";
import { ActiveSectionContext } from "../../../common/context/active-context";
import { BasicHeader } from "../../headers/basic/basic-header.styled";

export const OurTeam = ({ id }: WebSectionProps) => {
  React.useEffect(() => {
    team.sort(() => Math.random() - 0.5);
  }, []);
  const { text } = useText();
  const sectionRef = React.useRef<HTMLDivElement>(null);
  const {
    elementSize: { height }
  } = useResize(sectionRef.current!);

  const { setActiveSectionId } = React.useContext(ActiveSectionContext);
  useActiveSection(sectionRef, setActiveSectionId, id, height);

  const title: string[] = text("navigation.ourTeam").split(" ");

  return (
    <Section background={defaultTheme.colors.darkGrey} id={id}>
      <Align direction="column" align="center" justify="space-around">
        <OurTeamSectionDivider />
        <Title
          size="huge"
          color={defaultTheme.colors.white}
          align="center"
          data-sal="fade"
          data-sal-duration={durations.slow}
          data-sal-easing="ease-in-out"
          bold
        >
          <BasicHeader>
            {title[0]}
            <span>{title[1]}</span>
          </BasicHeader>
        </Title>
        <TeamMembersContainer ref={sectionRef}>
          <TeamCarousel />
        </TeamMembersContainer>
      </Align>
    </Section>
  );
};
