import React from "react";
import { useText } from "../../../../common/hooks";
import {
  OrbitContainer,
  TextContainer,
  TitleContainer,
  SubtitleContainer,
  BackgroundImageContainer,
  StepContainer,
  LeftContainer,
  OrbitAndLeftContainer,
  StepSubTitleAndTitleContainer,
  SubtitleAndTitleContainer,
  StyledArrowNext,
  ArrowImage,
  StyledArrowPrev
} from "./Orbit.styled";

interface OrbitProps {
  description: string;
  title: string;
  orbitIcon: any;
  activeIndex: number;
  step: string;
  subtitle: string;
  handleIndexChange: (next?: boolean) => void;
}
const leftArrow = require("../../../../assets/images/arrow-left.svg");
const rightArrow = require("../../../../assets/images/arrow-right.svg");

export const Orbit = ({
  description,
  title,
  orbitIcon,
  activeIndex,
  step,
  subtitle,
  handleIndexChange
}: OrbitProps) => {
  const { text } = useText();

  return (
    <OrbitAndLeftContainer>
      <LeftContainer>
        <StyledArrowPrev activeIndex={activeIndex} onClick={() => handleIndexChange()}>
          <ArrowImage src={leftArrow} />
        </StyledArrowPrev>
        <BackgroundImageContainer activeIndex={activeIndex} orbitIcon={orbitIcon} />
        <StepSubTitleAndTitleContainer>
          <StepContainer>{step}</StepContainer>
          <SubtitleAndTitleContainer>
            <SubtitleContainer>{text(`${subtitle}`)}</SubtitleContainer>
            <TitleContainer>{text(`${title}`)} </TitleContainer>
          </SubtitleAndTitleContainer>
        </StepSubTitleAndTitleContainer>
        <TextContainer>{text(`${description}`)}</TextContainer>
        <StyledArrowNext activeIndex={activeIndex} onClick={() => handleIndexChange(true)}>
          <ArrowImage src={rightArrow} />
        </StyledArrowNext>
      </LeftContainer>

      <OrbitContainer t={text} activeIndex={activeIndex}>
        <div className="launch">
          <div className="dotlaunch" />
          <div className="product">
            <div className="dotproduct" />
            <div className="uiux">
              <div className="dotuiux" />
              <div className="solution">
                <div className="dotsolution" />
                <div className="brainstorm">
                  <div className="dotbrainstorm" />
                  <div className="circle" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </OrbitContainer>
    </OrbitAndLeftContainer>
  );
};
