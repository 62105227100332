import React from "react";
import Slider from "react-slick";
import styled from "styled-components";
import { TeamMember } from "./TeamMember/TeamMember";

import { respondTo } from "../../styles/respondTo";
import { team } from "../../assets/mocks/team";

const leftArrow = require("../../assets/images/arrow-left.svg");
const rightArrow = require("../../assets/images/arrow-right.svg");

const TeamCarouselContainer = styled.div`
  width: 100%;
  margin: 0 auto;
  position: relative;
`;

const StyledArrow = styled.div`
  position: absolute;
  top: calc(50% - 80px);
  cursor: pointer;
  ${respondTo.mobile`
  top: calc(50% - 45px);
  `}
`;

const StyledArrowNext = styled(StyledArrow)`
  right: -8%;

  ${respondTo.desktop`
  right:-7%;
  `};
  ${respondTo.tablet`
  right: -12%;
  `};

  ${respondTo.tabletS`
  right: -9%;
  `}
  ${respondTo.mobileS`
   right: -15%;
  `}
`;
const StyledArrowPrev = styled(StyledArrow)`
  left: -8%;
  z-index: 1;

  ${respondTo.desktop`
  left: -7%;
  `};
  ${respondTo.tablet`
  left:-12%;
  `};

  ${respondTo.tabletS`
  left: -9%;
  `}
  ${respondTo.mobileS`
   left: -15%;
  `}
`;
const ArrowImage = styled.img`
  width: 40px;
  ${respondTo.tabletL`
  width: 30px;
  `}
  ${respondTo.tabletS`
  width:20px;
  `}
  ${respondTo.mobileS`
   width: 15px;
  `}
`;

const SampleNextArrow = props => {
  return (
    <>
      <StyledArrowNext onClick={props.onClick}>
        <ArrowImage src={rightArrow} />
      </StyledArrowNext>
    </>
  );
};
const SamplePrevArrow = props => {
  return (
    <StyledArrowPrev onClick={props.onClick}>
      <ArrowImage src={leftArrow} />
    </StyledArrowPrev>
  );
};

export const TeamCarousel = () => {
  const settings = {
    infinite: true,
    speed: 200,
    slidesToShow: 5,
    centerPadding: 100,
    autoplay: false,
    draggable: false,
    slidesToScroll: 1,
    cssEase: "linear",
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    responsive: [
      {
        breakpoint: 1400,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
          infinite: true
        }
      },
      {
        breakpoint: 1000,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true
        }
      },

      {
        breakpoint: 430,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true
        }
      }
    ]
  };

  return (
    <TeamCarouselContainer>
      <Slider {...settings}>
        {team.map((member, index) => (
          <TeamMember key={index} img={member.img} role={member.role} name={member.name} index={index} />
        ))}
      </Slider>
    </TeamCarouselContainer>
  );
};
