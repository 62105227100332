import * as React from "react";
import { Service } from "./service/Service";
import { defaultTheme } from "../../styles/theme";
import { Align } from "../UI/Align";
import { ServiceTilesGrid } from "./service/Service.styled";

interface ServicesProps {
  services: {
    id: number;
    title: string;
    description: string;
    img: string;
  }[];
}

export const Services = ({ services }: ServicesProps) => {
  return (
    <ServiceTilesGrid>
      {services.map((service, index) => (
        <Service
          index={index}
          key={service.id}
          title={service.title}
          img={service.img}
          description={service.description}
        />
      ))}
    </ServiceTilesGrid>
  );
};
