import * as React from "react";
import { defaultTheme } from "../../../styles/theme";
import { Align } from "../../UI/Align";
import { Text } from "../../UI/Text";
import { Title } from "../../UI/Title";
import { ServiceCard, ServiceImg, ServiceContainer, ServiceTitle } from "./Service.styled";
import { Button } from "../../UI/Button";
import { Divider } from "../../UI/Divider";

import tap from "../../../assets/images/tap.svg";
import { useViewport } from "../../../common/hooks";

interface ServiceProps {
  index: number;
  img: string;
  title: string;
  description: string;
}

export const Service = ({ title, description, img, index }: ServiceProps) => {
  const { isMobile, isDesktop } = useViewport();

  return (
    <ServiceCard data-sal={index % 2 ? "slide-left" : "slide-right"} data-sal-duration="900">
      <ServiceContainer direction="row">
        <ServiceImg src={img} alt={title} />
        <Align direction="column" align="left" justify={isMobile ? "start" : "center"}>
          <ServiceTitle>{title}</ServiceTitle>
          <Text
            spacing="0"
            margin={isMobile ? "12px" : "0px"}
            size={isMobile ? "large" : "small"}
            color={`${defaultTheme.colors.black}`}
            align={isMobile ? "center" : "left"}
          >
            {description}
          </Text>
        </Align>
        {/* <Button img={tap} onPress={() => {}} /> */}
      </ServiceContainer>
    </ServiceCard>
  );
};
