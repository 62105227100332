import * as React from "react";
import Slider from "react-slick";
import styled from "styled-components";
import { defaultTheme } from "../../../../styles/theme";
import { colors } from "../../../../styles/newColors";
import { respondTo } from "../../../../styles/respondTo";
import { Title } from "../../../UI";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const CarouselContainer = styled.div`
  div {
    outline: none;
    ${respondTo.desktop4k` 
    width: 1000px;
  `};
    ${respondTo.desktopMacP` 
    width: 600px;
    `};
    ${respondTo.desktopHD` 
    width: 550px;
    `};
    ${respondTo.desktop` 
    width: 450px;
  `};
    ${respondTo.desktop1200` 
  width: 400px;
`};
    ${respondTo.tabletS` 
width: 380px;
`};
    ${respondTo.mobile` 
width: 320px;
`};
    ${respondTo.mobileM` 
width: 300px;
`};
    ${respondTo.mobile360` 
width: 280px;
`};
    ${respondTo.mobile326` 
width: 240px;
`};
  }
`;

interface CarouselProps {
  words: string[];
}

export const Carousel = ({ words }: CarouselProps) => {
  const settings = {
    arrows: false,
    draggable: false,
    accessibility: false,
    adaptiveHeight: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    vertical: true,
    autoplay: true,
    autoplaySpeed: 1300,
    speed: 1200,
    pauseOnHover: false
  };
  return (
    <CarouselContainer>
      <Slider {...settings}>
        {words.map((word, index) => (
          <Title size="header" key={index} color={colors.green} bold>
            {word}
          </Title>
        ))}
      </Slider>
    </CarouselContainer>
  );
};
