import React from "react";
import { ICONS, STEPS } from "../../../../assets/mocks/workflow";
import { defaultTheme } from "../../../../styles/theme";
import { ProgressBarComponent } from "../ProgressBar/ProgressBarComponent";

import {
  WorkflowContinuationBarContainer,
  ProgressBarandOrbitContainer,
  ImageContainer,
  WorkflowIconImage,
  StepContainer,
  StepTitle,
  StepBorder
} from "./WorkflowContinuationBar.styled";

import { useText, useViewport } from "../../../../common/hooks";
interface WorkflowProps {
  icon: string;
  color: "white" | "green";
  index: number;
  onClick: (id: number) => void;
  description: string;
  subtitle: string;
  orbitIcon: any;
  step: string;
  setActiveIndex?: number;
  activeIndex?: number;
}

export const WorkflowContinuationBar = ({ setActiveIndex, activeIndex }) => {
  const { text } = useText();

  const PROGRESS_VALUE = {
    0: 20,
    1: 40,
    2: 60,
    3: 80,
    4: 100
  };
  return (
    <ProgressBarandOrbitContainer>
      <WorkflowContinuationBarContainer>
        <ImageContainer>
          {STEPS.map((step, index) => {
            const isBeforeActiveIndex = activeIndex >= index;
            return (
              <StepContainer onClick={() => setActiveIndex(index)}>
                <WorkflowIcon
                  key={index}
                  {...step}
                  color={isBeforeActiveIndex ? `green` : `white`}
                  onClick={setActiveIndex}
                ></WorkflowIcon>
                <StepTitle greenColor={isBeforeActiveIndex}>{text(`${step.title}`)}</StepTitle>
                <StepBorder
                  shouldLeftBorderBeDisplayed={index === 0}
                  borderColor={
                    isBeforeActiveIndex ? `${defaultTheme.colors.geeksGreen}` : `${defaultTheme.colors.white}`
                  }
                />
              </StepContainer>
            );
          })}
        </ImageContainer>
        <ProgressBarComponent completed={PROGRESS_VALUE[activeIndex]} />
      </WorkflowContinuationBarContainer>
    </ProgressBarandOrbitContainer>
  );
};

const WorkflowIcon = ({ icon, color, index, onClick }: WorkflowProps) => {
  return <WorkflowIconImage src={ICONS[icon][color]} alt="logo" onClick={() => onClick(index)}></WorkflowIconImage>;
};
