import React from "react";
import { useText } from "../../../../common/hooks";
import { defaultTheme } from "../../../../styles/theme";
import { Text } from "../../../UI";
import { companyData } from "./CompanyData";
import {
  CompanyContainer,
  InfomationRow,
  InfomationRowValue,
  LogoFooter,
  InfomationRowName
} from "./CompanyInfo.styled";

export const CompanyInfo = () => {
  const { text } = useText();
  const logo = require("../../../../assets/images/footer/geeknauts_logo.svg") as string;

  return (
    <CompanyContainer>
      <LogoFooter src={logo}></LogoFooter>
      <Text size={"huge"} color={defaultTheme.colors.black} spacing="0" align="left">
        {companyData.name.toUpperCase()}
      </Text>
      {companyData.details.map(data => (
        <InfomationRow>
          <InfomationRowName>
            <Text size={"medium"} color={defaultTheme.colors.black} spacing="0" align="left" bold>
              {data.name.length > 0 && text("companyAddress." + data.name)}
            </Text>
          </InfomationRowName>
          <InfomationRowValue>
            <Text size={"medium"} color={defaultTheme.colors.black} spacing="0" align="left" bold>
              {data.value}
            </Text>
          </InfomationRowValue>
        </InfomationRow>
      ))}
    </CompanyContainer>
  );
};
