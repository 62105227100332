import * as React from "react";

import {
  OurProjectsList,
  OurProjectsListMobile,
  OurProjectsInfo,
  OurProjectsWrapper,
  CardWrapper,
  TitleLine,
  Line,
  Dot,
  ProjectSubpage,
  SubpageTitle,
  SubpageTitleWrapper,
  OurProjectsSeparator
} from "./OurProjects.styled";
import { Projects } from "./Projects";
import { Section, Align } from "../../UI";
import { Title, DecoratedSectionTitle } from "../../UI/Title";
import { ActiveSectionContext } from "../../../common/context/active-context";
import { useActiveSection, useResize, useText, useViewport } from "../../../common/hooks";
import { WebSectionProps } from "../../../types/types";
import { defaultTheme } from "../../../styles/theme";
import { sectionBackgrounds } from "../../../styles/section.backgrounds";
import { navigate } from "gatsby";
import { BasicHeader } from "../../headers/basic/basic-header.styled";
const maturaProject = require("../../../assets/images/ourProjects/maturaProject.png");
const barberProject = require("../../../assets/images/ourProjects/barberProject.png");
const raceinsightProject = require("../../../assets/images/ourProjects/raceProject.png");
const xsaleProject = require("../../../assets/images/ourProjects/xsaleProject.png");
const plishkaProject = require("../../../assets/images/ourProjects/plishkaProject.png");
const rcpProject = require("../../../assets/images/ourProjects/rcpProject.png");
const vipclubProject = require("../../../assets/images/ourProjects/vipclubProject.png");
export const OurProjects = ({ id }: WebSectionProps) => {
  const { text } = useText();

  const sectionRef = React.useRef<HTMLDivElement>(null);
  const { setActiveSectionId } = React.useContext(ActiveSectionContext);
  const {
    elementSize: { height }
  } = useResize(sectionRef.current!);

  useActiveSection(sectionRef, setActiveSectionId, id, height);

  const projectsExposed = text([
    {
      id: 1,
      title: "ourProjects.project.matura.title",
      tags: ["Mobile", "React native", "Education"],
      img: maturaProject,
      url: "https://explore.geeknauts.com/matura-testy-i-zadania/"
    },
    {
      id: 2,
      title: "ourProjects.project.vipclub.title",
      tags: ["Mobile", "React Native", "Discounts App"],
      img: vipclubProject,
      url: "https://explore.geeknauts.com/love-vip-club/"
    },
    {
      id: 3,
      title: "ourProjects.project.xsale.title",
      tags: ["Web App", "React", "E-Commerce"],
      img: xsaleProject,
      url: "https://explore.geeknauts.com/x-sale/"
    }
  ]);

  const projects = text([
    {
      id: 4,
      title: "ourProjects.project.plishka.title",
      tags: ["Mobile", "React", "Smart Home"],
      img: plishkaProject,
      url: "https://explore.geeknauts.com/pliszka/"
    },
    {
      id: 5,
      title: "ourProjects.project.rcp.title",
      tags: ["Web app", "React", "Time Tracking"],
      img: rcpProject,
      url: "https://explore.geeknauts.com/rcp/"
    },

    {
      id: 6,
      title: "ourProjects.project.barber.title",
      tags: ["Mobile", "Flutter", "Booking"],
      img: barberProject,
      url: "https://explore.geeknauts.com/barber/"
    },
    {
      id: 7,
      title: "ourProjects.project.race.title",
      tags: ["Mobile", "Flutter", "Gaming"],
      img: raceinsightProject,
      url: "https://explore.geeknauts.com/raceinsight/"
    }
  ]);
  const projectsOnMobile = React.useMemo(
    () => [...projectsExposed, ...projects].sort((a, b) => 0.5 - Math.random()).slice(0, 3),
    []
  );
  const subpage: string[] = text("ourProjects.subpage.title").split(" ");
  return (
    <Section background={sectionBackgrounds.ourProjects} id={id}>
      <OurProjectsWrapper ref={sectionRef}>
        <Align direction="column" align="center" justify="space-between">
          <OurProjectsInfo data-sal="fade" data-sal-duration="1300">
            <BasicHeader>
              <span className="decorator first-letter-highlighted">{text("navigation.ourProjects")}</span>
            </BasicHeader>
          </OurProjectsInfo>
        </Align>
        <Align direction="column" align="end" justify="space-between">
          <OurProjectsSeparator></OurProjectsSeparator>
        </Align>
        <CardWrapper>
          <OurProjectsList>
            <Projects projects={projectsExposed} isExposed />
            <Align direction="column" align="center" justify="space-between">
              <OurProjectsSeparator></OurProjectsSeparator>
            </Align>
            <Projects projects={projects} />
          </OurProjectsList>
          <OurProjectsListMobile>
            <Projects projects={projectsOnMobile} />
          </OurProjectsListMobile>
        </CardWrapper>
        <Align direction="column" align="start" justify="space-between">
          <OurProjectsSeparator noMarginBottom></OurProjectsSeparator>
        </Align>
        <SubpageTitleWrapper>
          <ProjectSubpage
            onClick={() => {
              navigate("https://explore.geeknauts.com");
            }}
          >
            <SubpageTitle>
              <div className="highlighted">{subpage[0]}&nbsp;</div>
              <div>{subpage[1]}&nbsp;</div>
              <div>{subpage[2]}</div>
            </SubpageTitle>
          </ProjectSubpage>
        </SubpageTitleWrapper>
      </OurProjectsWrapper>
    </Section>
  );
};
