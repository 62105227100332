import styled from "styled-components";
import { defaultTheme } from "../../../../styles/theme";
import { respondTo } from "../../../../styles/respondTo";
interface TechIconsContainerProps {
  open: boolean;
}

export const TechnologyTypeContainer = styled.div`
  width: 500px;
  height: 500px;
  display: flex;

  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  border-radius: 280px;
  position: relative;
  ${respondTo.desktopL` 
  width: 430px;
  height: 430px;
  border-radius: 200px;
  `};
  ${respondTo.desktop` 
  width: 400px;
  height: 400px;
  border-radius: 200px;
  `};
  ${respondTo.desktopS` 
  width: 340px;
  height: 340px;
  border-radius: 175px;
  `};
  ${respondTo.tabletL` 
  width: 300px;
  height: 300px;
  border-radius: 150px;
  `};
  ${respondTo.mobileM` 
  width: 250px;
  height: 250px;
  border-radius: 150px;
  `};
`;

export const CircleImg = styled.img`
  position: relative;
  z-index: 1;
  object-fit: contain;
  width: 100%;
  height: 100%;
`;

export const AlignContent = styled.span`
  opacity: 0;
`;
export const CircleTitle = styled.div`
  display: flex;
  justify-content: center;
  position: relative;
  text-transform: uppercase;
  left: 3%;
  letter-spacing: 24px;
  font-size: 36px;
  height: 44px;
  top: -30px;
  margin-bottom: 80px;
  color: ${defaultTheme.colors.lightGrey};

  ${respondTo.desktopL`
  letter-spacing: 18px;
  font-size: ${defaultTheme.fontSize.xxl};
  top: -15px;
  `}
  ${respondTo.desktop`
  letter-spacing: ${defaultTheme.spacing.xxs};
  `}
  ${respondTo.desktopS`
  font-size: ${defaultTheme.fontSize.xl};
  top: 12px;
  `}
  ${respondTo.tabletL`
  top: 36px;
  `}
  ${respondTo.mobileM`
  font-size: ${defaultTheme.fontSize.l};
  letter-spacing: 6px;
  `}
`;

export const ContentContainer = styled.div`
  width: 500px;
  height: 500px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  z-index: 2;
  ${respondTo.desktopL`
  width: 430px;
  height: 430px; 
  width: 94%;
  `};
  ${respondTo.desktop` 
  width: 400px;
  height: 400px; 
  width: 93%;
  `};
  ${respondTo.desktopS` 
  width: 340px;
  height: 340px;
  width: 93%;
  `};
  ${respondTo.tabletL` 
  width: 340px;
  height: 300px;
  width: 92%;
  `};
  ${respondTo.mobileM` 
   width: 250px;
   height: 250px;
   width: 93%;
  `};
`;

export const TechIconsContainer = styled.div<TechIconsContainerProps>`
  display: flex;
  flex-wrap: wrap;
  height: 140px;
  top: -100px;
  padding: 0 40px;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: relative;
  z-index: 3;

  ${respondTo.desktopL`
  top: -80px;
  `}
  ${respondTo.desktop`
  padding: 0;
  `}
  ${respondTo.tabletL`
  top: -60px;
  `}
  ${respondTo.tablet`
  top: -50px;
  `}
  ${respondTo.mobile` 
  padding: 0 ;
  top: -40px;
  width: 250px;
  `};
`;

export const TechIconContainer = styled.div`
  z-index: ${({ zIndex }: { zIndex: string }) => (zIndex ? zIndex : "0")};
  width: 60px;
  transform: scale(1.4);
  position: relative;
  display: flex;
  justify-content: center;
  margin: 40px 40px;
  cursor: pointer;
  ${respondTo.desktopL`
  transform: scale(1);
  margin: 30px 20px;
  `}
  ${respondTo.desktopS`
  margin: 20px 10px`}
  ${respondTo.tabletL`
  width: 56px;
  transform: scale(0.9);
  margin: 15px 10px
  `}
  ${respondTo.mobileM`
  width: 48px;
  transform: scale(0.8);
  margin: 10px 10px;
  `}

  &:hover > * {
    visibility: visible;
  }
`;

export const TechTitle = styled.div`
  position: absolute;
  bottom: ${({ wide }: { wide: boolean }) => (wide ? "-55px" : "-35px")};
  visibility: hidden;
  text-align: left;
  left: 16px;
  background-color: ${defaultTheme.colors.mediumGrey};
  padding: 4px ${defaultTheme.spacing.xs};
  color: ${defaultTheme.colors.textLight};
  max-width: 100px;
  left: 20%;

  &::after {
    content: "";
    position: absolute;
    left: 12%;
    top: -10%;
    transform: rotate(45deg);
    width: 10px;
    height: 10px;
    background-color: ${defaultTheme.colors.mediumGrey};
  }
`;
