import * as React from "react";
import { navigate } from "gatsby";
import { FooterContainer, PrivacyPolicyContainer } from "./Footer.styled";
import { Section } from "../../UI";
import { useIntl } from "gatsby-plugin-react-intl";

export const Footer = () => {
  const intl = useIntl();
  const currentYear = new Date().getFullYear();
  return (
    <Section fullWidth>
      <FooterContainer>
        © Geeknauts {currentYear} |
        <PrivacyPolicyContainer
          onClick={() => {
            navigate("/privacy-policy");
          }}
        >
          {intl.locale === "pl" ? "Polityka Prywatności" : "Privacy Policy"}
        </PrivacyPolicyContainer>
      </FooterContainer>
    </Section>
  );
};
