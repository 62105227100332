import React, { useState } from "react";

import { WorkflowContinuationBar } from "./ContinuationBar/WorkflowContinuationBar";
import { WorkflowTitle, WorkflowContainer, WorkflowText } from "./Workflow.styled";

import { useResize, useActiveSection, useText } from "../../../common/hooks";
import { STEPS } from "../../../assets/mocks/workflow";
import { durations } from "../../../animations/animations";
import { ActiveSectionContext } from "../../../common/context/active-context";
import { WebSectionProps } from "../../../types/types";
import { defaultTheme } from "../../../styles/theme";
import { Title, Section } from "../../UI";
import { Orbit } from "./Orbit/Orbit";
import { BasicHeader } from "../../headers/basic/basic-header.styled";

export const Workflow = ({ id }: WebSectionProps) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const { text } = useText();

  const sectionRef = React.useRef<HTMLDivElement>(null);
  const {
    elementSize: { height }
  } = useResize(sectionRef.current!);

  const { setActiveSectionId } = React.useContext(ActiveSectionContext);
  useActiveSection(sectionRef, setActiveSectionId, id, height);

  const handleIndexdecreaseChange = () => {
    if (activeIndex > 0) {
      setActiveIndex(activeIndex - 1);
    }
  };

  const handleIndexIncreaseChange = () => {
    if (activeIndex < STEPS.length - 1) {
      setActiveIndex(activeIndex + 1);
    }
  };
  const handleIndexChange = (next: boolean = false) => {
    if (next) {
      handleIndexIncreaseChange();
    } else {
      handleIndexdecreaseChange();
    }
  };

  return (
    <Section fullWidth background={defaultTheme.colors.darkGrey} id={id}>
      <WorkflowContainer ref={sectionRef}>
        <BasicHeader>{text("navigation.workflow")}</BasicHeader>

        <WorkflowText>{text("workflow.text")}</WorkflowText>
        <Orbit
          handleIndexChange={handleIndexChange}
          description={STEPS[activeIndex].description}
          title={STEPS[activeIndex].title}
          subtitle={STEPS[activeIndex].subtitle}
          orbitIcon={STEPS[activeIndex].orbitIcon}
          activeIndex={activeIndex}
          step={STEPS[activeIndex].step}
        />
        <WorkflowContinuationBar setActiveIndex={setActiveIndex} activeIndex={activeIndex} />
      </WorkflowContainer>
    </Section>
  );
};
