import { keyframes } from "styled-components";

export const fadeIn = keyframes`
0%{opacity:0}
50%{opacity:0.5}
100%{opacity:1}
`;
export const scaleUp = keyframes`
0%{transform:scale(0)}
100%{transform:scale(1)}
`;
export const fadeInAndMoveTop = keyframes`
0%{opacity:0;transform:translateY(100px)}
50%{opacity:0.5;transform:translateY(-25px)}
100%{opacity:1;transform:translateY(0)}
`;
export const fadeInAndMoveBot = keyframes`
0%{opacity:0;transform:translateY(-100px)}
50%{opacity:0.5;transform:translateY(25px)}
100%{opacity:1;transform:translateY(0)}
`;

export const moveInLeft = keyframes`
0%{transform:translateX(350px)}
50%{transform:translateX(-45px)}
100%{transform:translateX(0)}
`;
export const moveInRight = keyframes`
0%{transform:translateX(-350px)}
50%{transform:translateX(45px)}
100%{transform:translateX(0)}
`;
export const moveInBot = keyframes`
0%{transform:translateY(-30px)}
50%{transform:translateY(10px)}
100%{transform:translateY(0)}
`;
export const moveInTop = keyframes`
0%{transform:translateY(-30px)}
50%{transform:translateY(10px)}
100%{transform:translateY(0)}
`;

export const rotate360 = keyframes`
0%{transform: rotate(0deg)}
100%{transform: rotate(360deg)}
`;

export const durations = Object.freeze({
  fast: 300,
  medium: 600,
  slow: 900,
});

export const delays = Object.freeze({
  short: 200,
  medium: 400,
  long: 600,
});
