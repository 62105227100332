import * as React from "react";
import { defaultTheme } from "../../../styles/theme";
import { Align } from "../../UI/Align";
import { navigate } from "gatsby";

import {
  OurProjectCard,
  OurProjectImg,
  ProjectLogo,
  HoverContainer,
  MobileContainer,
  OurProjectCardWrapper,
  CardTitle,
  CardTags,
  CardTag,
  NewHoverContainer,
  HoverImg
} from "./OurProject.styled";

interface OurProjectProps {
  img: string;
  logo: string;
  title: string;
  tags: string[];
  small?: boolean;
  url?: string;
  isExposed: boolean;
}

export const OurProject = ({ title, tags, img, logo, url, isExposed }: OurProjectProps) => {
  return (
    <OurProjectCardWrapper small={!isExposed}>
      <OurProjectCard small={!isExposed}>
        <HoverContainer
          data-sal="fade"
          data-sal-duration="1300"
          onClick={() => {
            navigate(`${url}`);
          }}
        >
          <OurProjectImg src={img} alt={title} small={!isExposed} />
        </HoverContainer>
        <CardTitle
          small={!isExposed}
          onClick={() => {
            navigate(`${url}`);
          }}
        >
          {title}
        </CardTitle>
        <CardTags>
          {tags.map(tag => (
            <CardTag>{tag}</CardTag>
          ))}
        </CardTags>
      </OurProjectCard>
    </OurProjectCardWrapper>
  );
};
