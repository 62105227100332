import styled from "styled-components";
import { defaultTheme } from "../../../styles/theme";
import { BasicHeaderProps } from "./basic-header";

export const BasicHeader = styled.div<BasicHeaderProps>`
  font-family: "Raleway";
  font-size: 64px;
  font-weight: 400;
  letter-spacing: 0.3em;
  text-indent: 0.3em;
  text-transform: uppercase;
  margin: 0 48px;
  white-space: nowrap;
  text-align: center;
  mix-blend-mode: ${({ blendMode }) => blendMode ?? "initial"};
  text-indent: 0;
  white-space: initial;

  @media (max-width: 1200px) {
    font-size: ${defaultTheme.fontSize.xxxxl};
  }

  @media (max-width: 1030px) {
    font-size: ${defaultTheme.fontSize.xxxl};
  }

  @media (max-width: 768px) {
    font-size: ${defaultTheme.fontSize.xxl};
  }
  @media (max-width: 375px) {
    font-size: ${defaultTheme.fontSize.xl};
  }
  span {
    display: inline-block;
    color: ${defaultTheme.colors.green};
  }

  span.decorator {
    color: inherit;

    &:after {
      content: "+";
      color: ${defaultTheme.colors.green};
    }
  }

  span.decorator-prefix {
    color: inherit;

    &:before {
      content: "+";
      color: ${defaultTheme.colors.green};
    }
  }

  span.first-letter-highlighted {
    &::first-letter {
      color: ${defaultTheme.colors.green} !important;
    }
  }
`;
