import * as React from "react";

import { OurServicesList, ServicesInfo, ServicesWrapper } from "./OurServices.styled";
import { Services } from "../../services/Services";
import { Section, Align, Title } from "../../UI";

import { ActiveSectionContext } from "../../../common/context/active-context";
import { useActiveSection, useResize, useText } from "../../../common/hooks";

import { WebSectionProps } from "../../../types/types";

import { defaultTheme } from "../../../styles/theme";
import { sectionBackgrounds } from "../../../styles/section.backgrounds";
import { BasicHeader } from "../../headers/basic/basic-header.styled";

const mobileDeveloper = require("../../../assets/images/ourServices/mobile-developer.svg");
const webDeveloper = require("../../../assets/images/ourServices/web-developer.svg");
const buildingWebsite = require("../../../assets/images/ourServices/building-website.svg");
const uxui = require("../../../assets/images/ourServices/uxui.svg");
const hosting = require("../../../assets/images/ourServices/hosting.svg");
const backend = require("../../../assets/images/ourServices/backend.svg");
const eCommerce = require("../../../assets/images/ourServices/eCommerce.svg");
const bodyLeasing = require("../../../assets/images/ourServices/bodyLeasing.svg");
const devops = require("../../../assets/images/ourServices/devops.svg");
export const OurServices = ({ id }: WebSectionProps) => {
  const { text } = useText();

  const sectionRef = React.useRef<HTMLDivElement>(null);
  const { setActiveSectionId } = React.useContext(ActiveSectionContext);

  const {
    elementSize: { height }
  } = useResize(sectionRef.current!);

  useActiveSection(sectionRef, setActiveSectionId, id, height);

  const services = text([
    {
      id: 1,
      title: "whatWeDo.services.mobile.title",
      description: "whatWeDo.services.mobile.description",
      img: mobileDeveloper
    },
    {
      id: 2,
      title: "whatWeDo.services.web.title",
      description: "whatWeDo.services.web.description",
      img: webDeveloper
    },
    {
      id: 3,
      title: "whatWeDo.services.webpage.title",
      description: "whatWeDo.services.webpage.description",
      img: buildingWebsite
    },
    {
      id: 4,
      title: "whatWeDo.services.design.title",
      description: "whatWeDo.services.design.description",
      img: uxui
    },
    {
      id: 5,
      title: "whatWeDo.services.hosting.title",
      description: "whatWeDo.services.hosting.description",
      img: hosting
    },
    {
      id: 6,
      title: "whatWeDo.services.backend.title",
      description: "whatWeDo.services.backend.description",
      img: backend
    },
    {
      id: 7,
      title: "whatWeDo.services.eCommerce.title",
      description: "whatWeDo.services.eCommerce.description",
      img: eCommerce
    },
    {
      id: 8,
      title: "whatWeDo.services.bodyLeasing.title",
      description: "whatWeDo.services.bodyLeasing.description",
      img: bodyLeasing
    },
    {
      id: 9,
      title: "whatWeDo.services.devops.title",
      description: "whatWeDo.services.devops.description",
      img: devops
    }
  ]);

  return (
    <Section background={sectionBackgrounds.services} id={id}>
      <ServicesWrapper ref={sectionRef}>
        <Align direction="column" align="center" justify="space-between">
          <ServicesInfo data-sal="fade" data-sal-duration="1300">
            <BasicHeader>{text("navigation.whatWeDo")}</BasicHeader>
          </ServicesInfo>
          <OurServicesList>
            <Services services={services} />
          </OurServicesList>
        </Align>
      </ServicesWrapper>
    </Section>
  );
};
