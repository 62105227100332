import styled from "styled-components";
import { respondTo } from "../../../styles/respondTo";
import { defaultTheme } from "../../../styles/theme";

interface ServiceContainer {
  direction: "row" | "column" | "row-reverse" | "column-reverse";
  align: string;
  justify: string;
  wrap?: boolean;
}

export const ServiceCard = styled.div`
  box-sizing: border-box;
  justify-content: center;
  display: flex;

  @media (min-width: 601px) and (max-width: 1000px) {
    &:last-child {
      grid-column: span 2;

      > div {
        max-width: calc(50% - 12px);
      }
    }
  }

  @media (min-width: 768px) {
    height: auto;
  }

  ${respondTo.mobile`
    border: 0;
    flex-basis: 0;
    margin: 0;
    height: auto;
    width: 100%;
  `}
`;

export const ServiceImg = styled.img`
  width: 60px;
  margin: ${defaultTheme.spacing.s};

  @media (min-width: 600px) and (max-width: 760px) {
    width: 45px;
    padding: 1px;
    padding-right: 15px;
    margin: 0;
  }

  ${respondTo.mobile`
    width: 100px;
    height:90px;
    margin-right: ${defaultTheme.spacing.m};
    margin-top: 0;
    `}

  @media (max-width: 430px) {
    margin-bottom: 15px;
    width: 70px;
  }
`;

export const Title = styled.div`
  border-top: solid 1px black;
  padding-top: 40px;
  margin-top: -40px;
`;

export const ServiceContainer = styled.div`
  display: flex;
  flex-direction: ${({ direction }) => direction};
  justify-content: start;
  align-items: center;
  flex-wrap: nowrap;
  padding: ${defaultTheme.spacing.xs};
  background-color: ${defaultTheme.colors.white};
  border: 1px solid ${defaultTheme.colors.black};
  height: 100%;
  width: 100%;
  box-sizing: border-box;
  max-width: 350px;

  ${respondTo.tablet`
    max-width: 100%;
  `}

  ${respondTo.mobile`
    border: none;
  `}

  ${respondTo.mobileM`
    flex-direction: column;
  `}

  @media (max-width: 430px) {
    padding-top: 0;
    padding-bottom: 0;
  }
`;

export const ServiceTitle = styled.div`
  text-align: left;
  color: ${defaultTheme.colors.black};
  font-size: ${defaultTheme.fontSize.xl};
  font-weight: 300;
  margin-bottom: ${defaultTheme.spacing.xxs};

  @media (max-width: 1000px) {
    text-align: center;
  }

  @media (max-width: 767px) {
    font-size: ${defaultTheme.fontSize.font18};
    letter-spacing: 3px;
    text-align: center;
  }
  @media (max-width: 430px) {
    margin-bottom: ${defaultTheme.spacing.small};
  }
`;

export const ServiceTilesGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  grid-auto-rows: 1fr;
  row-gap: 30px;
  column-gap: 60px;

  @media (min-width: 600px) and (max-width: 760px) {
    column-gap: 15px;
  }

  ${respondTo.desktop1200`
    grid-template-columns: repeat(2, minmax(0, 1fr));
  `}

  ${respondTo.mobile`
    grid-template-columns: repeat(1, minmax(0, 1fr));
  `}

  @media (max-width: 430px) {
    grid-auto-rows: initial;
    row-gap: 15px;
  }
`;
