import styled from "styled-components";
import { respondTo } from "../../../styles/respondTo";
import { defaultTheme } from "../../../styles/theme";

export const FooterContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${defaultTheme.colors.brandBlack};
  color: ${defaultTheme.colors.white};
  height: 70px;

  @media (max-width: 360px) {
    font-size: 0.8rem;
  }
`;

export const PrivacyPolicyContainer = styled.div`
  cursor: pointer;
  margin-left: 4px;
  &:hover {
    color: ${defaultTheme.colors.green};
  }
`;
