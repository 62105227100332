import * as React from "react";
import { OurProject } from "./OurProject";
import { Align } from "../../UI/Align";

interface ProjectsProps {
  projects: {
    id: number;
    title: string;
    img: string;
    logo: string;
    tags: string[];
    url: string;
  }[];
  isExposed?: boolean;
}

export const Projects = ({ projects, isExposed }: ProjectsProps) => {
  return (
    <Align direction="row" justify="space-between" align="start" gap="30px" wrap>
      {projects.map(project => (
        <OurProject
          isExposed={isExposed ?? false}
          key={project.id}
          title={project.title}
          img={project.img}
          logo={project.logo}
          tags={project.tags}
          url={project.url}
        />
      ))}
    </Align>
  );
};
