export const testimonials = [
  {
    id: 1,
    name: "Sergiusz Dobosz",
    job: "CEO of Grupa StudentNews.pl",
    testimonial:
      "Geeknauts customer service was outstanding. We actually felt like a bunch of friends - they were creating our product like their own. They really wanted it to be the best. (...) I would recommend Geeknauts to anyone looking for a serious software development company and to those that wants their product to be taken care of.",
    url: "https://clutch.co/profile/geeknauts#review-1807455"
  },

  {
    id: 2,
    name: "Piotr Drągowski",
    job: "CEO of Point B",
    testimonial:
      "After speaking to many candidate software houses from around the world, I chose Geeknauts following a conversation with the CEO who unlike others took his time to deeply understand the solution to an impressive level. This was exactly what we looked for in a partner.",
    url: "https://clutch.co/profile/geeknauts#review-1812894"
  },
  {
    id: 3,
    name: "Wojtek Malagocki",
    job: "eCommerce Manager & xSale Product Owner",
    testimonial:
      "Geeknauts has successfully migrated the client's administrative panel, and it is being used daily by the client's customers. The client is seeing an increase in their net promoter score (NPS), and the team from Geeknauts is providing a well-organized team that is highly involved in the project.",
    url: "https://clutch.co/profile/geeknauts#review-2128910"
  },
  {
    id: 4,
    name: "Konrad Neneman",
    job: "Board Member, Gallium sp. z o.o.",
    testimonial:
      "Geeknauts delivered a working app that allows users to run devices according to their personal configuration. The team delivered on time and collaborated via online meetings with the client to solve issues. Moreover, they were skilled and competent and offered a reasonable price.",
    url: "https://clutch.co/profile/geeknauts#review-2124231"
  }
];
