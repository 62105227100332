import * as React from "react";
import { navigate } from "gatsby";

import {
  ContactUsContainer,
  SmallFormErrorMessage,
  SmallForm,
  SmallFormContainer,
  ContactUsTitle,
  LeftFooterContainer,
  RightFooterContainer,
  Plus,
  TextContainer,
  FollowUsTextContainer,
  SubmitButtonContainer,
  CompanyWrapper,
  IconContainer,
  IconImg,
  LogoImg,
  LeftAndRightContainer
} from "./ContactUs.styled";

import { Section, Text, TextInput } from "../../UI";

import { useActiveSection, useResize, useText, useScrollPosition } from "../../../common/hooks";

import { ActiveSectionContext } from "../../../common/context/active-context";

import { sectionBackgrounds } from "../../../styles/section.backgrounds";
import { defaultTheme } from "../../../styles/theme";

import { WebSectionProps } from "../../../types/types";

import { delays, durations } from "../../../animations/animations";
import { useForm } from "react-hook-form";
import { ErrorModal } from "../../ErrorModal/ErrorModal";
import { CompanyInfo } from "./CompanyInfo/CompanyInfo";
import { SubmitButton } from "./SubmitButton";
import { useIntl } from "gatsby-plugin-react-intl";

export const Contact = ({ id }: WebSectionProps) => {
  const clutchIcon = require("../../../assets/images/footer/clutch-icon-footer.svg") as string;

  const logoMta = require("../../../assets/images/footer/mta_logo.svg");

  const instagramIcon = require("../../../assets/images/footer/instagram-icon-footer.svg") as string;

  const linkedinIcon = require("../../../assets/images/footer/linkedin-icon-footer.svg") as string;

  const { pageYPosition } = useScrollPosition();

  const sectionRef = React.useRef<HTMLDivElement>(null);
  const modalRef = React.useRef<any>(null);

  const { setActiveSectionId } = React.useContext(ActiveSectionContext);

  const { register, handleSubmit, errors } = useForm();

  const {
    elementSize: { height }
  } = useResize(sectionRef.current!);

  useActiveSection(sectionRef, setActiveSectionId, id, height);

  const { text } = useText();

  const url = "https://be.geeknauts.com/contact-form";

  const intl = useIntl();

  const onSubmitHandler = handleSubmit(async () => {
    const formData = new FormData(document.querySelector("form") || undefined);

    const dataToSend = {
      email: formData.getAll("email").join(", "),
      message: formData.getAll("message").join(", ")
    };

    try {
      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify(dataToSend)
      });
      if (response.status === 200) {
        navigate("/success");
      } else {
        modalRef.current.openModal();
      }
    } catch (err) {
      modalRef.current.openModal();
    }
  });

  const socialMedia = [
    { link: "https://clutch.co/profile/geeknauts#summary", logo: clutchIcon },
    { link: "https://www.linkedin.com/company/geeknauts/mycompany/", logo: linkedinIcon },
    { link: "https://www.instagram.com/geeknauts/", logo: instagramIcon }
  ];

  return (
    <Section background={sectionBackgrounds.contact} id={id} contentWidth={"100vw"}>
      <ContactUsContainer ref={sectionRef}>
        <ContactUsTitle lang={intl.locale}>
          <Plus>+</Plus>
          {text("contact.title")}
        </ContactUsTitle>
        <LeftAndRightContainer>
          <LeftFooterContainer>
            <SmallFormContainer
              data-sal={"slide-left"}
              data-sal-duration={durations.slow}
              data-sal-easing="ease-in-out"
              data-sal-delay={delays.short}
            >
              <TextContainer>
                <Text
                  size="large"
                  fontFamily="Raleway"
                  color={defaultTheme.colors.white}
                  spacing="0"
                  data-sal="slide-down"
                  data-sal-duration={durations.slow}
                  data-sal-easing="ease-in-out"
                >
                  {text("contact.text")}
                </Text>

                <Text
                  size="large"
                  fontFamily="Raleway"
                  color={defaultTheme.colors.white}
                  spacing="0"
                  data-sal="slide-down"
                  data-sal-duration={durations.slow}
                  data-sal-easing="ease-in-out"
                >
                  {text("contact.text2")}
                </Text>
              </TextContainer>

              <SmallForm onSubmit={e => onSubmitHandler(e)}>
                <TextInput
                  name="email"
                  type="text"
                  label={text("contact.inputs.email.label")}
                  placeholder={text("contact.inputs.email.placeholder")}
                  rows={1}
                  register={register({
                    required: true,
                    pattern: {
                      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                      message: "Wrong email address"
                    }
                  })}
                  error={errors.email}
                />
                {errors.email && <SmallFormErrorMessage>{text("contact.inputs.email.error")}</SmallFormErrorMessage>}
                <TextInput
                  name="message"
                  type="area"
                  // label={text("contact.inputs.message.label")}
                  placeholder={text("contact.inputs.message.placeholder")}
                  rows={8}
                  register={register({ required: true, minLength: 1 })}
                  error={errors.message}
                />
                {errors.message && (
                  <SmallFormErrorMessage>{text("contact.inputs.message.error")}</SmallFormErrorMessage>
                )}
                <SubmitButtonContainer>
                  <SubmitButton onPress={() => {}} />
                </SubmitButtonContainer>
              </SmallForm>
            </SmallFormContainer>
          </LeftFooterContainer>
          <RightFooterContainer>
            <CompanyWrapper>
              <CompanyInfo data-sal="slide-left" data-sal-duration={durations.slow} />
            </CompanyWrapper>
            <CompanyWrapper>
              <FollowUsTextContainer>{text("contact.underLogo")}</FollowUsTextContainer>
              <IconContainer>
                {socialMedia.map(media => (
                  <a href={media.link}>
                    <IconImg src={media.logo} />
                  </a>
                ))}
              </IconContainer>
              <a href="https://mobiletrends.pl/kto-wygral-mta-2022/" target="_blank">
                <LogoImg src={logoMta} alt="mta logo" />
              </a>
            </CompanyWrapper>
          </RightFooterContainer>
        </LeftAndRightContainer>
      </ContactUsContainer>
      <ErrorModal ref={modalRef} top={pageYPosition} />
    </Section>
  );
};
