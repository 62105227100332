import styled from "styled-components";
import { defaultTheme } from "../../../styles/theme";
import { respondTo } from "../../../styles/respondTo";
export const WorkflowContainer = styled.div`
  display: flex;
  margin-bottom: 30px;
  margin-top: 58px;
  flex-direction: column;
  align-items: center;
  color: #fff;

  ${respondTo.mobile`
    padding-bottom: 150px;
  `}
`;
export const WorkflowTitle = styled.div`
  font-family: "Raleway";
  font-size: ${defaultTheme.fontSize.xxxxxxl};
  font-weight: 300;
  text-transform: uppercase;
  margin: ${defaultTheme.spacing.l} auto;
  letter-spacing: ${defaultTheme.spacing.xxs};
  ${respondTo.tablet`
  font-size:${defaultTheme.fontSize.xxxxxl};
  `}
  ${respondTo.tabletS`
  font-size:${defaultTheme.fontSize.xxxxl};
  `}
  ${respondTo.mobile`
  font-size:${defaultTheme.fontSize.xxxl};
  letter-spacing: ${defaultTheme.spacing.base};
  `}
  ${respondTo.mobile400`
  font-size:${defaultTheme.fontSize.xxl};
  `};
  ${respondTo.mobileS`
  font-size:${defaultTheme.fontSize.xl};
  `};
`;
export const WorkflowText = styled.div`
  font-family: "Raleway";
  text-align: center;
  color: ${defaultTheme.colors.white};
  font-size: ${defaultTheme.fontSize.xl};
  margin-top: ${defaultTheme.spacing.s};
  width: 90%;
  ${respondTo.mobile`
  font-size: ${defaultTheme.fontSize.m};
  width: 80%;
    `};
  ${respondTo.mobileS`
  font-size: ${defaultTheme.fontSize.m};
    `};
`;
