import styled from "styled-components";
import { respondTo } from "../../../styles/respondTo";
import { defaultTheme } from "../../../styles/theme";

export const TechnologiesContainer = styled.div`
  justify-content: center;
  width: 100%;
  flex-direction: column;
  display: flex;
  align-items: center;
  color: ${defaultTheme.colors.white};
  padding-top: 40px;
`;

export const TechnologiesSubtitleContainer = styled.div`
  margin: ${defaultTheme.spacing.s};
  padding: 40px;
  text-align: center;
  ${respondTo.tabletL` 
  margin: ${defaultTheme.spacing.xs};
  padding: 20px;
  width: 80%;
  `};
`;

export const TechnologiesTitle = styled.div`
  text-transform: uppercase;
  color: ${defaultTheme.colors.textLight};
  z-index: 1;
  justify-content: center;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  margin-left: 0.3em;
  margin-top: 30px;
  font-size: ${defaultTheme.fontSize.xxxxxxl};
  letter-spacing: ${defaultTheme.spacing.xxs};

  ${respondTo.tablet` 
  margin-left: 10px;
  font-size: ${defaultTheme.fontSize.xxxxxl}
  `};
  ${respondTo.tabletS` 
  font-size: ${defaultTheme.fontSize.xxxxl}
  `};
  ${respondTo.mobile` 
  font-size: ${defaultTheme.fontSize.xxxl};
  letter-spacing: ${defaultTheme.spacing.base};
  `};
  ${respondTo.mobileM` 
  font-size: ${defaultTheme.fontSize.xxxl};
  letter-spacing: ${defaultTheme.spacing.base};
  `};
  ${respondTo.mobileS` 
  font-size: ${defaultTheme.fontSize.xl};
  `};
`;

export const MoonTitle = styled.div`
  height: 0.9em;
  width: 0.9em;
  border-radius: 0.55em;
  background-color: ${defaultTheme.colors.textLight};
  z-index: 2;
  position: absolute;
  ${respondTo.desktop4k` 
  left:  ${({ plmargin }: { plmargin: boolean }) => (plmargin ? "49%" : "55%")};
  `};
  ${respondTo.desktopMacP` 
  left:  ${({ plmargin }: { plmargin: boolean }) => (plmargin ? "49%" : "46%")};
  `};
  ${respondTo.desktopMac` 
  left:  ${({ plmargin }: { plmargin: boolean }) => (plmargin ? "48.5%" : "45%")};
  `};
  ${respondTo.desktopHD` 
  left:  ${({ plmargin }: { plmargin: boolean }) => (plmargin ? "50%" : "48.5%")};
  `};
  ${respondTo.desktopL` 
  left:  ${({ plmargin }: { plmargin: boolean }) => (plmargin ? "50%" : "48.3%")};
  `};
  ${respondTo.desktop` 
  left:  ${({ plmargin }: { plmargin: boolean }) => (plmargin ? "50%" : "48%")};
  `};
  ${respondTo.desktop1200` 
  left:  ${({ plmargin }: { plmargin: boolean }) => (plmargin ? "50%" : "47.5%")};
  `};
  ${respondTo.tabletL` 
  left:  ${({ plmargin }: { plmargin: boolean }) => (plmargin ? "49%" : "46.5%")};
  `};
  ${respondTo.tabletS` 
  left:  ${({ plmargin }: { plmargin: boolean }) => (plmargin ? "48.5%" : "46%")};
  `};
  ${respondTo.mobile` 
  left:  ${({ plmargin }: { plmargin: boolean }) => (plmargin ? "48.5%" : "46%")};
  `};
  ${respondTo.mobileM` 
  left:  ${({ plmargin }: { plmargin: boolean }) => (plmargin ? "48.5%" : "45%")};
  `};
`;

export const Line = styled.div`
  height: 1px;
  left: 0px;
  width: 100vw;
  top: 50%;
  background-color: ${defaultTheme.colors.textLight};
  z-index: 0;
  position: relative;
  opacity: 0.5;
  ${respondTo.tablet` 
width: 1px;
height: 100%;
left: 50%;
top: 0;
`};
`;

export const CirclesContainer = styled.div`
  top: 30%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  width: 100vw;
  justify-content: center;
  margin-bottom: 30px;
  align-items: center;

  ${respondTo.tablet` 
    flex-direction: column;
  `};
`;
