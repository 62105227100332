import React, { useState } from "react";
import { testimonials } from "../../../assets/mocks/testimonials";
import { ActiveSectionContext } from "../../../common/context/active-context";
import { useActiveSection } from "../../../common/hooks/useActiveSection";
import { useResize } from "../../../common/hooks/useResize";
import { useText } from "../../../common/hooks/useText";
import { defaultTheme } from "../../../styles/theme";
import { WebSectionProps } from "../../../types/types";
import { Align, Section, Text } from "../../UI";
import { useViewport } from "../../../common/hooks";
import {
  TestimonialContainer,
  TestimonialsAuthorJob,
  TestimonialsAuthorName,
  TestimonialsAuthorWraper,
  TestimonialsNavigationWraper,
  TestimonialsTitle,
  TestimonialsWrapper,
  TestimonialsArrowRight,
  TestimonialsArrowLeft,
  TestimonialDotWraper,
  TestimonialDot,
  TestimonialsQuoteLeft,
  TestimonialsQuoteRight,
  TestimonialsText,
  TestimonialsClutchImageContainer,
  BackgroundImgTestimonialsContainer,
  BackgroundImgTestimonialsWrapper,
  TestimonialsArrowRightContainer,
  TestimonialsArrowLeftContainer,
  DescriptionContainer,
  BackgroundImgTestimonialsMask,
  TestimonialsHeader
} from "./Testimonials.style";
import { Title } from "../../UI/Title";
import { BasicHeader } from "../../headers/basic/basic-header.styled";

//require("../../../assets/images/clients/futuritilogo.png");
const clutch = require("../../../assets/images/Clutch_pod_reviewsy.svg");
const leftArrow = require("../../../assets/images/left-arrow.svg");
const rightArrow = require("../../../assets/images/right-arrow.svg");
const testimonialsQuote = require("../../../assets/images/testimonials-quote.svg");
export const Testimonials = ({ id }: WebSectionProps) => {
  const { text } = useText();
  const sectionRef = React.useRef<HTMLDivElement>(null);
  const { setActiveSectionId } = React.useContext(ActiveSectionContext);

  const {
    elementSize: { height }
  } = useResize(sectionRef.current!);

  useActiveSection(sectionRef, setActiveSectionId, id, height);
  const [index, setIndex] = useState(0);
  const {
    name,
    testimonial,
    job,
    url
  }: {
    name: string;
    testimonial: string;
    job: string;
    url: string;
  } = testimonials[index];

  const prevTestimonial = () => {
    if (index > 0) {
      setIndex(index - 1);
    }
  };

  const nextTestimonial = () => {
    if (index < testimonials.length - 1) {
      setIndex(index + 1);
    }
  };

  return (
    <Section background={defaultTheme.colors.white} id={id}>
      <TestimonialsWrapper ref={sectionRef}>
        <BackgroundImgTestimonialsContainer></BackgroundImgTestimonialsContainer>
        <Align direction="column" align="left" justify="space-around">
          <TestimonialsHeader blendMode="difference">{text("navigation.testimonials")}</TestimonialsHeader>
          <DescriptionContainer>
            <Align direction="row" align="left" justify="space-around">
              <TestimonialsQuoteRight src={testimonialsQuote} alt="testimonialsQuote" />
              <TestimonialsArrowLeftContainer>
                <TestimonialsArrowLeft activeIndex={index} onClick={prevTestimonial} src={leftArrow} alt="leftArrow" />
              </TestimonialsArrowLeftContainer>
              <TestimonialsContainer name={name} testimonial={testimonial} job={job} url={url} />
              <TestimonialsArrowRightContainer>
                <TestimonialsArrowRight
                  activeIndex={index}
                  onClick={nextTestimonial}
                  src={rightArrow}
                  alt="rightArrow"
                />
              </TestimonialsArrowRightContainer>
            </Align>
          </DescriptionContainer>
        </Align>
      </TestimonialsWrapper>
    </Section>
  );
};

const TestimonialsContainer = ({ testimonial, name, job, url }) => {
  return (
    <TestimonialContainer>
      <TestimonialsAuthor name={name} job={job} />
      <TestimonialsText>{testimonial}</TestimonialsText>
      {url !== "" ? <TestimonialsClutchImage url={url} /> : null}
    </TestimonialContainer>
  );
};

const TestimonialsAuthor = ({ name, job }) => {
  return (
    <TestimonialsAuthorWraper>
      <TestimonialsAuthorName>{name}</TestimonialsAuthorName>
      <TestimonialsAuthorJob>{job}</TestimonialsAuthorJob>
    </TestimonialsAuthorWraper>
  );
};

const TestimonialsClutchImage = ({ url }) => {
  return (
    <a href={url} target="_blank">
      <TestimonialsClutchImageContainer src={clutch} alt="clutch" />
    </a>
  );
};
