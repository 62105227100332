import { createContext } from "react";
import { WebSection } from "../../types/types";

type ActiveSectionContextType = {
  activeSectionId: WebSection;
  setActiveSectionId: (id: WebSection) => void;
};

export const ActiveSectionContext = createContext<ActiveSectionContextType>({
  activeSectionId: "home",
  setActiveSectionId: (id: WebSection) => {},
});
