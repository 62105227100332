import * as React from "react";
import { ThemeProvider } from "styled-components";

import { Navigation } from "../navigation/Navigation";

import { defaultTheme } from "../../styles/theme";
import { GlobalStyles } from "../../styles/GlobalStyles";
import { useText } from "../../common/hooks/useText";

interface LayoutProps {
  children: React.ReactNode;
}

export const Layout = ({ children }: LayoutProps) => {
  const { text } = useText();

  const navOptions = text([
    { id: "services", name: "navigation.whatWeDo" },
    {
      id: "ourProjects",
      name: "navigation.ourProjects",
    },
    {
      id: "technologies",
      name: "navigation.technologies",
    },
    { id: "team", name: "navigation.ourTeam" },
    // { id: "workflow", name: "navigation.workflow" },
    { id: "contact", name: "navigation.contact" },
  ]);

  return (
    <ThemeProvider theme={defaultTheme}>
      <GlobalStyles />
      <Navigation navOptions={navOptions} />
      {children}
    </ThemeProvider>
  );
};
