const studentNews = require("../images/clients/studentnews.svg");
const futuriti = require("../images/clients/futuriti.svg");
const sigma = require("../images/clients/sigma.svg");
const lineberries = require("../images/clients/lineberries.png");
const thesupermarketinggroup = require("../images/clients/thesupermarketinggroup.png");
const colorStudentNews = require("../images/clients/colorstudentnews.svg");
const colorFuturiti = require("../images/clients/colorfuturiti.svg");
const colorSigma = require("../images/clients/colorsigma.svg");
const colorLineberries = require("../images/clients/colorlineberries.png");
const colorthesupermarketinggroup = require("../images/clients/colorthesupermarketinggroup.png");
interface ClientBox {
  index: number;
  title: string;
  img: any;
  hoverImg: any;
  url: string;
}

export const clients: ClientBox[] = [
  {
    index: 1,
    title: "futuriti",
    img: futuriti,
    hoverImg: colorFuturiti,
    url: "https://futuriti.pl/"
  },
  {
    index: 2,
    title: "studentnews",
    img: studentNews,
    hoverImg: colorStudentNews,
    url: "https://www.studentnews.pl/"
  },
  {
    index: 3,
    title: "sigma",
    img: sigma,
    hoverImg: colorSigma,
    url: "https://www.sigmaconnectivity.com/"
  },
  {
    index: 4,
    title: "lineberries",
    img: lineberries,
    hoverImg: colorLineberries,
    url: "https://lineberries.com/"
  },
  {
    index: 5,
    title: "the supermarketing group",
    img: thesupermarketinggroup,
    hoverImg: colorthesupermarketinggroup,
    url: "https://www.thesupermarketinggroup.com/"
  }
];
